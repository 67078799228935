/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../../../../_mexablock/helpers';

type Props = {
  data: any;
  callback: () => void;
};

const BannerSaldo: React.FC<Props> = ({ data, callback }) => {
  return (
    <div className="card card-flush h-md-100">
      <div
        className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
        style={{
          backgroundPosition: '100% 50%',
          backgroundImage: `url('${toAbsoluteUrl(
            '/media/illustrations/sketchy-1/fondo.png'
          )}')`,
        }}
      >
        <div className="mb-10">
          <div className="fs-2hx fw-bold text-gray-800 text-center mb-13">
            <span className="me-2">
              Te has quedado sin{' '}
              <span className="position-relative d-inline-block text-danger">
                <span className="text-danger opacity-75-hover">saldo</span>

                <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
              </span>
              ? haz clic en el siguinte enlace.
            </span>
          </div>

          <div className="text-center">
            <a
              href="#/"
              className="btn btn-sm btn-success fw-bold"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_upgrade_plan"
              onClick={callback}
            >
              Recargar saldo
            </a>
          </div>
        </div>

        <img
          className="mx-auto h-150px h-lg-200px"
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/4.png')}
          alt=""
        />
      </div>
    </div>
  );
};

export { BannerSaldo };
