import { useState, useEffect } from 'react';
import { getAllNotifications, getNotificationsUsers } from '../../../service/users/Usuarios';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
    show: boolean,
    realoadGrid: number,
    filters: any
  ) => {
    const [count, setCount] = useState(0);

    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const res = await getNotificationsUsers(filters)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setCount(res.count);
        }
      };
      if (show && realoadGrid) {
        fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realoadGrid]);
    return { count };
  };


export const useGetAllNotifications = (
    realoadGrid: number,
  ) => {
    const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllNotifications()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
    return { data, loadingRequest };
  };
