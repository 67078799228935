import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  userHasRole,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
  toAbsoluteUrl,
  formatPrice,
} from '../../../../../_mexablock/helpers';
import * as auth from '../../../../modules/auth/redux/AuthRedux';
import clsx from 'clsx';
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepperComponent } from '../../../../../_mexablock/assets/ts/components';
import { useSelectOffers } from '../../activacion/hooks/ActivacionHook';
import { createRecharge } from '../../../../service/operacion/Recargas';
import { Recharge } from '../models/RecargaModel';
import { useSelectClients } from '../../../clientes/hooks/ClientesHook';
import { validateMSISDN } from '../../../../service/operacion/Validacion';
import { getUserByToken } from '../../../../modules/auth/redux/AuthCRUD';

// import {
//   validarIMEI,
//   validateICCID,
//   validateEmail,
// } from '../../../../service/operacion/Validacion';
// import { useSelectOffers } from '../hooks/ActivacionHook';
// import { createActivation } from '../../../../service/operacion/Activaciones';
// import { useSelectGeneric } from '../../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};

const schemaNewRecharge = [
  Yup.object({
    phoneFormat: Yup.string()
      .required('Requerido.')
      .min(10, 'Formato de teléfono inválido'),
    phoneConfirmFormat: Yup.string()
      .required('Requerido.')
      .min(10, 'Formato de teléfono inválido')
      .oneOf(
        [Yup.ref('phoneFormat')],
        'Los números de teléfono deben coincidir.'
      ),
  }),
  Yup.object({
    offer_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
];

const initValues: Recharge = {
  offer_id: 0,
  phone: '',
  phoneFormat: '',
  phoneConfirm: '',
  phoneConfirmFormat: '',
  customer_id: 0,
};

const NewRechargeModal: React.FC<Props> = ({ show, handleClose }) => {
  // OBTIENE ROL USUARIO
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validateBack, setValidateBack] = useState<boolean>(true);
  const [offerName, setOfferName] = useState<string>('');
  const { data: offers } = useSelectOffers(show, 'recharge');
  const { data: customers } = useSelectClients(show);

  // STEPPER
  const [currentSchema, setCurrentSchema] = useState(schemaNewRecharge[0]);
  const [textStepper, setTextStepper] = useState('Continuar');
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (show) {
      loadStepper();
    }
  }, [stepperRef, show]);

  const formNewRecharge = useFormik({
    initialValues: initValues,
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            validateMSISDN(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                stepper.current.goNext();
                setCurrentSchema(schemaNewRecharge[1]);
                setTextStepper('Recargar');
                setLoading(false);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);

          break;
        case 2:
          setLoading(true);
          setTimeout(() => {
            createRecharge(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                const { mensaje } = res.data.message;
                setOfferName(mensaje);
                stepper.current.goNext();
                setTextStepper('Finalizar');
                setValidateBack(false);
                setLoading(false);

                // UPDATE SALDO
                const user = await getUserByToken();
                dispatch(auth.actions.fulfillUser(user));
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;
        case 3:
          if (!stepper.current) {
            return;
          }
          handleClose(true);
          stepper.current.goto(1);
          setTextStepper('Continuar');
          setOfferName('');
          setCurrentSchema(schemaNewRecharge[0]);
          formNewRecharge.resetForm();
          setSelectedOption(null);
          setLoading(false);
          break;
      }
    },
  });

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(schemaNewRecharge[stepper.current.currentStepIndex - 1]);
    switch (stepper.current.currentStepIndex - 1) {
      case 1:
        setTextStepper('Continuar');
        break;
      case 2:
        setTextStepper('Continuar');
        break;
      default:
        setTextStepper('Continuar');
        break;
    }
  };

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);

    formNewRecharge.setFieldValue('phone', maskedPhoneNumber);
    formNewRecharge.setFieldValue(
      'phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };
  const handlePhoneConfirmChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formNewRecharge.setFieldValue('phoneConfirm', maskedPhoneNumber);
    formNewRecharge.setFieldValue(
      'phoneConfirmFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );

    // setTimeout(() => {
    //   formNewRecharge.setErrors({});
    // }, 2000);
  };

  const handleCustomerChange = (selectedOption: any) => {
    // const id = selectedOption.value;
    const phone = selectedOption.phone;
    setSelectedOption(selectedOption);
    const maskedPhoneNumber = maskPhoneNumber(phone);

    // LLENA LOS INPUT DEL PRIMER NUMERO
    formNewRecharge.setFieldValue('phone', maskedPhoneNumber);
    formNewRecharge.setFieldValue(
      'phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );

    // LLENA LOS INPUT DEL SEGUNDO NUMERO
    formNewRecharge.setFieldValue('phoneConfirm', maskedPhoneNumber);
    formNewRecharge.setFieldValue(
      'phoneConfirmFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );

    setTimeout(() => {
      formNewRecharge.setErrors({});
    }, 5);
  };

  return (
    <Modal
      id="kt_modal_new_recharge"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        stepper?.current?.goto(1);
        formNewRecharge.resetForm();
        setCurrentSchema(schemaNewRecharge[0]);
        setTextStepper('Continuar');
        setSelectedOption(null);
        setOfferName('');
        setValidateBack(true);
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              stepper?.current?.goto(1);
              formNewRecharge.resetForm();
              setCurrentSchema(schemaNewRecharge[0]);
              setTextStepper('Continuar');
              setSelectedOption(null);
              setOfferName('');
              setValidateBack(true);
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Nueva Recarga</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Número a recargar</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Ofertas</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Completada</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-800px pt-10 pb-10"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formNewRecharge.handleSubmit}
            >
              <div
                className="current min-h-550px"
                data-kt-stepper-element="content"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  {userHasRole(['ADMIN_COMPANY'], userRoles) && (
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      <div className="w-100 d-flex">
                        <label className="required fs-6 fw-semibold form-label mb-2">
                          Empleado
                        </label>
                        {formNewRecharge.touched.customer_id &&
                          formNewRecharge.errors.customer_id && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {formNewRecharge.errors.customer_id}
                              </span>
                            </div>
                          )}
                      </div>

                      <Select
                        options={customers}
                        placeholder={'Seleccione'}
                        onChange={handleCustomerChange}
                        value={selectedOption}
                        noOptionsMessage={() => {
                          return <span>No hay registros</span>;
                        }}
                        styles={{
                          placeholder: (provided, state) => ({
                            ...provided,
                            color: '#181c32',
                          }),
                          indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: 'none',
                          }),
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                            color: '#7E8199',
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            border: '1px solid #e4e6ef',
                            borderRadius: '6px',
                            padding: '3px',
                            fontSize: '1.1rem',
                            boxShadow: 'none',
                            backgroundColor: state.isDisabled
                              ? '#EFF2F5'
                              : 'white',
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '1.1rem',
                          }),
                        }}
                      />
                    </div>
                  )}

                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Número de teléfono
                      </label>
                      {formNewRecharge.touched.phoneFormat &&
                        formNewRecharge.errors.phoneFormat && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewRecharge.errors.phoneFormat}
                            </span>
                          </div>
                        )}
                    </div>

                    <input
                      className="form-control mb-3"
                      value={formNewRecharge.values.phone}
                      placeholder="Número de teléfono"
                      disabled={userHasRole(['ADMIN_COMPANY'], userRoles)}
                      prefix=""
                      onChange={handlePhoneChange}
                    />
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Confirmar número de teléfono
                      </label>
                      {formNewRecharge.touched.phoneConfirmFormat &&
                        formNewRecharge.errors.phoneConfirmFormat && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewRecharge.errors.phoneConfirmFormat}
                            </span>
                          </div>
                        )}
                    </div>

                    <input
                      className="form-control mb-3"
                      value={formNewRecharge.values.phoneConfirm}
                      placeholder="Confirmar número de teléfono"
                      disabled={userHasRole(['ADMIN_COMPANY'], userRoles)}
                      prefix=""
                      onChange={handlePhoneConfirmChange}
                    />
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Seleciona una oferta
                      </label>
                      {formNewRecharge.touched.offer_id &&
                        formNewRecharge.errors.offer_id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewRecharge.errors.offer_id}
                            </span>
                          </div>
                        )}
                    </div>
                    {offers.map((item: any, index: number) => (
                      <a
                        href="#/"
                        key={index}
                        className={clsx(
                          'btn btn-outline btn-flex flex-column flex-stack pb-5 ms-5 mt-5 border-hover-primary',
                          {
                            'btn-active-light-primary active':
                              formNewRecharge.values.offer_id === item.id,
                          }
                        )}
                        style={{
                          width: '200px',
                          height: '150px',
                          border: 'solid 1px #dbdfe9',
                        }}
                        onClick={() => {
                          formNewRecharge.setFieldValue(`offer_id`, item.id);
                        }}
                      >
                        <div className="">
                          <span
                            className={clsx(
                              ' fw-bold fs-2 d-block text-hover-primary ',
                              {
                                ' text-primary':
                                  formNewRecharge.values.offer_id === item.id,
                                ' text-gray-800':
                                  formNewRecharge.values.offer_id !== item.id,
                              }
                            )}
                          >
                            {`${item.gb} GB`}
                          </span>
                          <span
                            className={clsx(' fw-semibold fs-5', {
                              ' text-primary':
                                formNewRecharge.values.offer_id === item.id,
                              ' text-gray-600':
                                formNewRecharge.values.offer_id !== item.id,
                            })}
                          >
                            {`${formatPrice(item.price, 'es-MX', 'MXN', 2, 2)}`}
                          </span>
                          <span
                            className={clsx('  fw-semibold fs-7', {
                              ' text-primary':
                                formNewRecharge.values.offer_id === item.id,
                              ' text-gray-500':
                                formNewRecharge.values.offer_id !== item.id,
                            })}
                          >
                            {` / ${item.commercial_time}`}
                          </span>
                        </div>

                        <div
                          className={clsx(' fw-semibold fs-6 mt-1', {
                            ' text-primary':
                              formNewRecharge.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewRecharge.values.offer_id !== item.id,
                          })}
                        >
                          {item.share_internet ? (
                            <KTSVG
                              className="svg-icon-3 svg-icon-success"
                              path="/media/icons/duotune/arrows/arr016.svg"
                            />
                          ) : (
                            <KTSVG
                              className="svg-icon-3"
                              path="/media/icons/duotune/arrows/arr015.svg"
                            />
                          )}
                          Comparte internet
                        </div>
                        <div
                          className={clsx(' fw-semibold fs-6  mt-1', {
                            ' text-primary':
                              formNewRecharge.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewRecharge.values.offer_id !== item.id,
                          })}
                        >
                          <KTSVG
                            className="svg-icon-3"
                            path="/media/icons/duotune/communication/com013.svg"
                          />
                          {`${item.minutes} Minutos`}
                        </div>
                        <div
                          className={clsx(' fw-semibold fs-6  mt-1', {
                            ' text-primary':
                              formNewRecharge.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewRecharge.values.offer_id !== item.id,
                          })}
                        >
                          <KTSVG
                            className="svg-icon-3"
                            path="/media/icons/duotune/communication/com003.svg"
                          />
                          {`${item.sms} SMS`}
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100">
                  <div className="text-center">
                    <h1 className="fw-bold text-primary">Recarga exitosa!</h1>

                    <div className="fw-semibold text-muted fs-4">
                      Se activo la oferta correctamente:
                    </div>
                    <h1 className="fw-bold text-dark mt-10">{offerName}</h1>
                  </div>

                  <div className="text-center px-4">
                    <img
                      className="mx-auto h-300px h-lg-300px"
                      src={toAbsoluteUrl(
                        '/media/illustrations/sketchy-1/ok.png'
                      )}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {validateBack && (
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Regresar
                    </button>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { NewRechargeModal };
