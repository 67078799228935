// useApi.ts
import { useState, useEffect } from 'react';
import { getAll } from '../../../../service/operacion/Recargas';
import { RecargasModel } from '../models/RecargaModel';
import { useHistory } from 'react-router-dom';

const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<RecargasModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, realoadGrid]);
  return { data, loadingRequest };
};

export {
    useGetAll
};