/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword';
import { ConfirmPassword } from './components/ConfirmPassword';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_mexablock/helpers';
import Lottie from 'lottie-react';
import animationData from '../../../_mexablock/assets/json/animation_llps1y53.json';
import animation from '../../../_mexablock/assets/json/animation_llpto72j.json';
// import { Seo } from '../../../SEO/Seo';

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <>
      {/* <Seo
      title='Learning React Helmet!'
      description='Beginner friendly page for learning React Helmet.'
      name='Company name.'
      type='article' /> */}
      <div
        className="d-flex w-100 h-100"
        // style={{
        //   backgroundImage: `url(${toAbsoluteUrl(
        //     '/media/illustrations/sketchy-1/14.png'
        //   )})`,
        // }}
      >
        <div className="flex-column w-50">
          <div
            className="w-400px"
            style={{
              left: 0,
              bottom: 0,
              position: 'absolute',
            }}
          >
            <Lottie animationData={animationData} />
          </div>
        </div>
        <div className="flex-column w-auto">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 mt-20">
            <a href="#" className="mb-12">
              <img
                alt="Logo"
                src={toAbsoluteUrl('/media/logos/logo-softnet.svg')}
                className="h-100px"
              />
            </a>
            <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route
                  path="/auth/confirm-password/:token"
                  component={ConfirmPassword}
                />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
        <div className="flex-column w-50" style={{ position: 'relative' }}>
          <div
            className="w-350px"
            style={{
              right: 0,
              bottom: 0,
              position: 'absolute',
            }}
          >
            <Lottie animationData={animation} />
          </div>
        </div>
      </div>
    </>
  );
}
