import React, { useState, useEffect } from 'react';
import { KTSVG, LoadingGrid } from '../../../../_mexablock/helpers';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { CreateModal } from '../_modals/CreateModal';
import { useGetAll } from '../hooks/EmpresasHook';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole, toAbsoluteUrl } from '../../../../_mexablock/helpers';
import { MenuComponent } from '../../../../_mexablock/assets/ts/components';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API;


const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const history = useHistory();
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setTitleModal('Nuevo');
    setVisivilityModal(!visivilityModal);
  };

  const columns: any = [
    {
      id: 'profile_photo',
      name: '',
      cell: (row: any) => imageRender(row.user),
      width: '10%',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.type.name,
      sortable: true,
      width: '10%',
    },
    {
      id: 'empresa',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.name,
      sortable: true,
      width: '15%',
    },
    {
      id: 'codigo',
      name: <div className="fw-bolder text-muted me-5">Código</div>,
      selector: (row: any) => row.code,
      cell: (row: any) => (
        <span className="text-primary fw-bolder">{row.code}</span>
      ),
      sortable: true,
      width: '10%',
    },
    {
      id: 'encargado',
      name: <div className="fw-bolder text-muted me-5">Encargado</div>,
      selector: (row: any) =>
        `${row.user.first_name} ${row.user.father_last_name} ${row.user.mother_last_name}`,
      sortable: true,
      width: '25%',
    },
    {
      id: 'correo',
      name: <div className="fw-bolder text-muted me-5">Correo</div>,
      selector: (row: any) => row.user.email,
      sortable: true,
      width: '20%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '15%',
    },
  ];

  const imageRender = (user: any) => {
    return (
      <>
        <div className="symbol symbol-40px me-5">
          <img
            src={
              user.profile_photo
                ? `${process.env.REACT_APP_PUBLIC}users/${user.profile_photo}`
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            crossOrigin="anonymous"
            className=""
            alt=""
          />
        </div>
      </>
    );
  };

  MenuComponent.reinitialization();

  const dropdownRender = (item: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                onClick={() => {
                  history.push(`/empresa/socio/${item.id}`)
                }}
              >
                Editar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  onRemoveRegister(item.id);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const onRemoveRegister = (id: string) => {
    axios.delete(`${API_URL}catalogos/empresas/${id}`)

      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }


  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                {userHasRole(['SYSTEM_ADMIN'], userRoles) && data.length === 0 && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light-primary"
                    id="kt_toolbar_primary_button"
                    onClick={addRegister}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    Nuevo
                  </a>
                )}
                {userHasRole(['ADMIN_MEXABLOCK'], userRoles) && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light-primary"
                    id="kt_toolbar_primary_button"
                    onClick={addRegister}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    Nuevo
                  </a>
                )}
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={0}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
    </>
  );
};

export { View };
