import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAll(
  filters: any
) {
  // let search: string = '';
//   if (filters) {
//     if (filters.nombre !== '') {
//       search += `&nameOpLike=%${filters.nombre}%`;
//     }
//   }
  // const response = await axios.get(
  //   `${API_URL}catalogos/activation?limit=${startIndex}&page=${endIndex}${search}`
  // );
  const response = await axios.get(
    `${API_URL}catalogos/activation`
  );
  const data = response.data.doc.data;
  return data;
}

// CAMBIO DE LOS CAROS
export async function getOffers(filter: string) {
  const response = await axios.get(
    `${API_URL}operation/offers/saldo/${filter}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getSelectCodes() {
  const response = await axios.get(`${API_URL}reports/codes`);
  const data = response.data.doc.data;
  let dataSelect: any = data;
  dataSelect.forEach(( row:any ) => {
      row.label = row.code;
      row.value = row.code;
  });
  return dataSelect;
}

export async function createActivation(body: any) {
  const { iccid, offer_id, schedule_date, email, first_name, mother_last_name, father_last_name, birthdate, gender_id, inmediality, imei } = body;
  const dateActivation = new Date(schedule_date)
  .toISOString()
  .split('T')[0];
  const dateBirthdate = new Date(birthdate)
  .toISOString()
  .split('T')[0];
  const response = await axios.post(`${API_URL}operation/activation/`, {
    iccid: iccid,
    offer_id: Number(offer_id),
    schedule_date: ( Number(inmediality) === 1) ? null : dateActivation,
    correo: email,
    nombre: first_name,
    apellido_paterno: father_last_name,
    apellido_materno: mother_last_name,
    fecha_nacimiento: dateBirthdate,
    gender_id: Number(gender_id),
    imei:imei
  });
  return response;
}