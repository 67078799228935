/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { formatPrice } from '../../../../_mexablock/helpers';

type Props = {
  data: any;
  loadingGrid: boolean;
  tipoRole: any;
};

const SaldosEmpresas: React.FC<Props> = ({ data, loadingGrid, tipoRole }) => {
  return data.length > 0 ? (
    <div className="card card-flush h-xl-100">
      <div className="card-header pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark fs-3">
            {tipoRole === 'ADMIN_MEXABLOCK' && 'Saldo socio / empresa.'}
            {tipoRole === 'ADMIN_PARTNER' && 'Saldo distribuidores.'}
          </span>

          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            Se muestra el saldo total.
          </span>
        </h3>
      </div>
      <div className="card-body">
        {loadingGrid ? (
          <div className="d-flex align-items-center">
            <div className="text-muted text-center fs-6 w-100 m-5">
              <span className="indicator-progress" style={{ display: 'block' }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </div>
          </div>
        ) : (
          data.map((row: any, index: number) => (
            <div key={index}>
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-3">
                  <div className="flex-grow-1">
                    <a
                      href="#"
                      className="text-gray-600 text-hover-primary fs-5 fw-bold lh-0"
                    >
                      {row.distributor
                        ? row.distributor.first_name
                        : row.company.name}
                    </a>
                  </div>
                </div>

                <div className="d-flex align-items-center w-100 mw-125px">
                  <span className="text-gray-800 fw-bold fs-5 fw-bold lh-0">
                    {formatPrice(row.virtual_balance, 'es-MX', 'MXN', 2, 2)}
                  </span>
                </div>
              </div>
              <div className="separator separator-dashed my-4"></div>
            </div>
          ))
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export { SaldosEmpresas };
