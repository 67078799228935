import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/json/animation_llqtt4e7.json';

export const BannerComingSoon: React.FC = () => {
  return (
    <>
      <div className="mb-2">
        <div className="d-flex align-items-center flex-column text-center">
          <div className="w-250px">
            <Lottie animationData={animationData} />
          </div>
        </div>
      </div>
    </>
  );
};
