import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import {
  KTSVG,
  maskPhoneNumber,
  LoadingGrid,
} from '../../../../../_mexablock/helpers';
import { TopUpBalanceModal } from '../../../../../_mexablock/partials/modals/balance/TopUpBalanceModal';
import { NewRechargeModal } from '../_modals/RechargeModal';
import { RechargeMultipleModal } from '../_modals/RechargeMultipleModal';
import { NoBalanceModal } from '../../../../../_mexablock/partials/modals/balance/NoBalanceModal';
import { useGetAll } from '../hooks/RecargasHook';
import { TableList } from '../../../../../_mexablock/helpers/components/TableList';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { saldo } = user;
  const [loadingView, setloadingView] = useState(true);
  const [idRow, setIdRow] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityTopUpModal, setVisivilityTopUpModal] = useState(false);
  const [visivilityNoBalanceModal, setVisivilityNoBalanceModal] =
    useState(false);
  const [visivilityRechargeModal, setVisivilityRechargeModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    if (Number(saldo) > 0) {
      setTitleModal('Recarga');
      setVisivilityModal(!visivilityModal);
    } else {
      setVisivilityNoBalanceModal(!visivilityNoBalanceModal);
    }
  };
  const topUpMultipleBalance = () => {
    setVisivilityTopUpModal(!visivilityTopUpModal);
  };

  const columns: any = [
    {
      id: 'fecha',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => row.fecha_format,
      cell: (row: any) => row.fecha,
      sortable: true,
      width: '300px',
    },
    {
      id: 'msisdn',
      name: <div className="fw-bolder text-muted me-5">MSISDN</div>,
      selector: (row: any) => maskPhoneNumber(row.msisdn),
      sortable: true,
      width: '150px',
    },
    // {
    //   id: 'iccid',
    //   name: <div className="fw-bolder text-muted me-5">ICCID</div>,
    //   selector: (row: any) => row.iccid,
    //   sortable: true,
    //   width: '200px',
    // },
    {
      id: 'oferta',
      name: <div className="fw-bolder text-muted me-5">Oferta</div>,
      selector: (row: any) => row.oferta,
      sortable: true,
      width: '230px',
    },
    {
      id: 'monto',
      name: <div className="fw-bolder text-muted me-5">Monto</div>,
      selector: (row: any) => row.montoFormat,
      cell: (row: any) => row.monto,
      sortable: true,
      width: '150px',
    },
    {
      id: 'folio',
      name: <div className="fw-bolder text-muted me-5">Folio</div>,
      selector: (row: any) => row.folio,
      sortable: true,
      width: '150px',
    },
    {
      id: 'codigo',
      name: <div className="fw-bolder text-muted me-5">Código</div>,
      selector: (row: any) => row.codigo,
      cell: (row: any) => (
        <span className="text-primary fw-bolder">{row.codigo}</span>
      ),
      sortable: true,
      width: '170px',
    },
    {
      id: 'usuario',
      name: <div className="fw-bolder text-muted me-5">Usuario</div>,
      selector: (row: any) => row.usuario,
      sortable: true,
      width: '200px',
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  data-kt-menu="true"
                  className="btn btn-sm btn-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nueva recarga
                </a>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <a
                      className="menu-link px-3"
                      data-kt-users-table-filter="delete_row"
                      href="#/"
                      onClick={addRegister}
                    >
                      Única
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      className="menu-link px-3"
                      data-kt-users-table-filter="delete_row"
                      href="#/"
                      onClick={topUpMultipleBalance}
                    >
                      Multiple
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <NewRechargeModal
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            // setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityModal(!visivilityModal);
        }}
      ></NewRechargeModal>
      <RechargeMultipleModal
        id_row={idRow}
        title={titleModal}
        show={visivilityTopUpModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
          }
          setIdRow(0);
          setVisivilityTopUpModal(!visivilityTopUpModal);
        }}
      ></RechargeMultipleModal>
      <NoBalanceModal
        show={visivilityNoBalanceModal}
        balance={Number(saldo)}
        handleClose={(updateGrid: boolean) => {
          setVisivilityNoBalanceModal(!visivilityNoBalanceModal);
        }}
        rechargeBalance={() => {
          setVisivilityNoBalanceModal(!visivilityNoBalanceModal);
          setVisivilityRechargeModal(!visivilityRechargeModal);
        }}
      ></NoBalanceModal>
      <TopUpBalanceModal
        show={visivilityRechargeModal}
        handleClose={(updateData: boolean) => {
          setVisivilityRechargeModal(!visivilityRechargeModal);
        }}
      />
    </>
  );
};

export { View };
