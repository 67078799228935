import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getAllEmpleados(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.nombre !== '') {
      search += `&nameOpLike=%${filters.nombre}%`;
    }
  }
  const response = await axios.get(
    `${API_URL}catalogos/employee?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getOneEmpleados(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/employee/${id_row}`);
  const { employee, id, alias, first_name, father_last_name, mother_last_name, email, phone, curp, rfc, birthdate, active, gender   } = response.data.doc.data;
  return {
    id: employee.id || 0,
    employee_number: employee.employee_number || '',
    rfc: "",
    manager: {
      id: id || 0,
      alias: alias || '',
      first_name: first_name || '',
      father_last_name: father_last_name || '',
      mother_last_name: mother_last_name || '',
      email: email || '',
      phone: maskPhoneNumber(phone) || '',
      phoneFormat: cleanPhoneNumber(phone) || '',
      curp: curp || '',
      rfc: rfc || '',
      birthdate: birthdate || new Date(),
      active: active || false,
      gender: {
        id: gender.id || 0,
        code: gender.code || '',
        name: gender.name || '',
      },
    }
};
}

export async function addEmpleados(body: any) {
  const { employee_number, manager: {  email, first_name, father_last_name, mother_last_name, phoneFormat, curp, rfc, birthdate, gender } } = body;
  const data = {
    "employee_number": employee_number,
    "manager": {
        "email": email,
        "first_name": first_name,
        "father_last_name": father_last_name,
        "mother_last_name": mother_last_name,
        "phone": phoneFormat,
        "curp": curp,
        "rfc": rfc,
        "birthdate": birthdate,
        "gender_id": (gender.id === 0) ? null : gender.id,
    }
  };
  const response = await axios.post(`${API_URL}catalogos/employee`, data);
  return response;
}
export async function updateEmpleados(body: any) {

  const { id: employee_id, employee_number, manager: {  id: user_id, email, first_name, father_last_name, mother_last_name, phoneFormat, curp, rfc, birthdate, gender } } = body;
  const data = {
    "employee_number": employee_number,
    "manager": {
        "email": email,
        "first_name": first_name,
        "father_last_name": father_last_name,
        "mother_last_name": mother_last_name,
        "phone": phoneFormat,
        "curp": curp,
        "rfc": rfc,
        "birthdate": birthdate,
        "gender_id": (gender.id === 0) ? null : gender.id,
    }
  };
  const response = await axios.put(`${API_URL}catalogos/employee/${employee_id}/user/${user_id}`, data);
  return response;
}

export async function removeEmpleados(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/employee/${id}`);
  return response;
}
