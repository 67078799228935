import React, { useState, useEffect, useRef } from 'react';
import { KTSVG, userHasRole } from '../../../../../_mexablock/helpers';
import { useFormik } from 'formik';
import Select from 'react-select';

import { useSelectTypes } from '../hooks/PartnersHook';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  showFilterCode: boolean;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  showFilterCode,
}) => {
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const { data: types } = useSelectTypes(true, showFilterCode);

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter({ ...values, filter: 1 });
    },
  });

  const handleTypes = (selectedOption: any) => {
    const id = selectedOption.value;
    setSelectedOption(selectedOption);
    formFilter.setFieldValue('type_id', id);
  };

  return (
    <>
      <div className="card-title align-items-start flex-column">
        <a
          href="#/"
          className={
            formFilter.values.filter !== 0
              ? ' btn btn-sm btn-primary'
              : 'btn btn-sm btn-light-primary'
          }
          data-kt-menu-trigger="click"
          data-kt-menu-placement="right-start"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-300px ms-2"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <div className="separator border-gray-200"></div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">Fecha inicial:</label>
                <input
                  type="date"
                  className="form-control"
                  ref={dateInputRef}
                  onKeyDown={(e) => e.preventDefault()}
                  value={formFilter.values.init_date}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formFilter.setFieldValue('init_date', fecha);
                    }
                  }}
                ></input>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Fecha fin:</label>
                <input
                  type="date"
                  className="form-control"
                  onKeyDown={(e) => e.preventDefault()}
                  value={formFilter.values.end_date}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formFilter.setFieldValue('end_date', fecha);
                    }
                  }}
                ></input>
              </div>
              {showFilterCode && (
                <div className="mb-5">
                  <label className="form-label fw-bold">Tipo:</label>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Select
                      options={types}
                      placeholder={'Seleccione'}
                      onChange={handleTypes}
                      value={selectedOption}
                      noOptionsMessage={() => {
                        return <span>No hay registros</span>;
                      }}
                      styles={{
                        placeholder: (provided, state) => ({
                          ...provided,
                          color: '#181c32',
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display: 'none',
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: '#7E8199',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border: '1px solid #e4e6ef',
                          borderRadius: '6px',
                          padding: '3px',
                          fontSize: '1.1rem',
                          boxShadow: 'none',
                          backgroundColor: state.isDisabled
                            ? '#EFF2F5'
                            : 'white',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '1.1rem',
                        }),
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    setSelectedOption(null);
                    onCleanFilter();
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
