import React, { useState, useEffect } from 'react';
import { KTSVG, SimpleAlert } from '../../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { approvisionamiento } from '../../../../service/operacion/Cuentas';
import * as auth from '../../../../modules/auth/redux/AuthRedux';
import { getUserByToken } from '../../../../modules/auth/redux/AuthCRUD';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  data: any;
  dataPago: any;
};
const schemaBalance = Yup.object().shape({
  new_balance: Yup.number().min(1, ' Requerido.').required('Requerido.'),
  new_balance2: Yup.number().min(1, ' Requerido.').required('Requerido.'),
});

const AprovisionarModal: React.FC<Props> = ({ data, show, handleClose }: any) => {
  const [balanceSum, setBalanceSum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [eff, setEff] = useState<number>(0);
  const [virt, setVirt] = useState<number>(0);
  const dispatch = useDispatch();

  const formBalance = useFormik({
    initialValues: data,
    validationSchema: schemaBalance,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {

        if (selectedFile !== null) {
          approvisionamiento(values)
            .then(async (res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              const user = await getUserByToken();
              dispatch(auth.actions.fulfillUser(user));
              formBalance.resetForm();
              handleClose(true);
              setLoading(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message },
              } = err.response;
              resMessageToast = `${message}`;
              SimpleAlert(resMessageToast, 3000, 'error');
              setLoading(false);
            });
        } else {
          setFileError(true);
          setLoading(false);
        }
      }, 1000);
    },
  });

  const handleFileUpload = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileError(false);
  };

  useEffect(() => {
    setEff(0);
    setVirt(0);
    setFileError(false);
    setSelectedFile(null);
  }, [show]);

  useEffect(() => {
    if (data) {
      let banderaAdmin: string = localStorage.getItem('persist:v100-demo1-auth')!;
      const banderaAdminBool = banderaAdmin.search("Administrador Socio")
      const { company, distributor } = data;

      if (banderaAdminBool != -1) {
        formBalance.setFieldValue(
          'distributorId',
          distributor?.id
        );
      } else {
        formBalance.setFieldValue(
          'companyId',
          company?.id
        );
      }


    }
  }, [data]);

  useEffect(() => {

    if (eff > 0) {
      const balance = Number(data.balance);
      setBalanceSum(balance + eff * 1.1);
    } else {
      setBalanceSum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eff]);

  useEffect(() => {
    if (virt > 0) {
      const balance = Number(data.balance);
      const balanceCurrent = Number(virt);
      setBalanceSum(balance + balanceCurrent); // Si hay que subir porcentaje      
    } else {
      setBalanceSum(0);
    }
  }, [virt]);

  useEffect(() => {
    formBalance.setFieldValue(
      'pdfFile',
      selectedFile
    );
  }, [selectedFile]);

  useEffect(() => {
    if (eff || virt) {
      setEff(data.comision == 'EFF' ? eff : virt * .90);
      setVirt(data.comision == 'SVV' ? virt : eff * 1.10);
      formBalance.setFieldValue(
        'new_balance',
        data.comision == 'EFF' ? eff : virt * .90
      );
      formBalance.setFieldValue(
        'new_balance2',
        data.comision == 'SVV' ? virt : eff * 1.10
      );
    }
  }, [eff, virt]);

  useEffect(() => {
    if (virt) {
      formBalance.setFieldValue(
        'saldoVirtualAmount',
        virt
      );
    }
  }, [virt]);


  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-5">
            <h1 className="mb-3">Aprovisionar Saldo</h1>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <div className="d-flex text-center mb-9">
              <div className="w-50 border border-dashed border-gray-300 rounded mx-2 p-4">
                <div className="fs-6 fw-semibold mb-2 text-muted">
                  {formBalance.values.distributor
                    ? 'Distribuidor'
                    : formBalance.values.company?.type?.name}
                </div>
                <div className="fs-2 fw-bold">
                  {formBalance.values.distributor
                    ? formBalance.values.distributor.first_name
                    : formBalance.values.company.name}
                </div>
              </div>
              <div className="w-50 border border-dashed border-gray-300 rounded mx-2 p-4">
                <div className="fs-6 fw-semibold mb-2 text-muted">
                  Número de cuenta
                </div>
                <div className="fs-2 fw-bold ">
                  {formBalance.values.account_number}
                </div>
              </div>
            </div>
            <div className="d-flex text-center mb-9">
              <div className="w-50 border border-dashed border-gray-300 rounded mx-2 p-4">
                <div className="fs-6 fw-semibold mb-2 text-muted">
                  Saldo Actual
                </div>
                <div className="fs-2 fw-bold">
                  <CountUp
                    end={data.balance}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </div>
              </div>
              <div className="w-50 border border-dashed border-gray-300 rounded mx-2 p-4">
                <div className="fs-6 fw-semibold mb-2 text-muted">
                  Nuevo Saldo
                </div>
                <div className="fs-2 fw-bold">
                  <CountUp
                    end={balanceSum}
                    duration={1}
                    prefix="$"
                    className="text-success"
                    decimals={2}
                  />
                </div>
              </div>
            </div>

            <form
              className="form"
              action="#"
              onSubmit={formBalance.handleSubmit}
            >
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fs-6 fw-semibold form-label mb-2">
                    Saldo Efectivo
                  </label>
                  {formBalance.touched.new_balance &&
                    formBalance.errors.new_balance && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formBalance.errors.new_balance}
                        </span>
                      </div>
                    )}
                </div>
                <NumericFormat
                  name="new_balance"
                  className="form-control mb-3"
                  value={eff}
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale
                  placeholder="$ 0.00"
                  prefix="$ "
                  maxLength={15}
                  thousandSeparator=","
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setEff(Number(floatValue))
                  }}
                  disabled={data.comision == "EFF" ? false : true}
                />

                {/* Segundo Input */}

                <div className="w-100 d-flex">
                  <label className="required fs-6 fw-semibold form-label mb-2">
                    Saldo Virtual
                  </label>
                  {formBalance.touched.new_balance2 &&
                    formBalance.errors.new_balance2 && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formBalance.errors.new_balance2}
                        </span>
                      </div>
                    )}
                </div>

                <NumericFormat
                  name="new_balance2"
                  className="form-control mb-3"
                  value={virt}
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale
                  placeholder="$ 0.00"
                  prefix="$ "
                  maxLength={15}
                  thousandSeparator=","
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setVirt(Number(floatValue));
                  }}
                  disabled={data.comision == "SVV" ? false : true}
                />
              </div>

              <div className="fv-row mb-7" style={{ textAlign: "center" }}>
                <h3 className='required'>Agregar Comprobante</h3>{
                  fileError ? <div className='text-danger fs-7'>El archivo es requerido</div> : null
                } <br></br>
                <input className="form-control" type="file" onChange={handleFileUpload} id="formFile" />
              </div>

              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  Agregue una nota
                </label>

                <textarea
                  className="form-control rounded-3 mb-5"
                  {...formBalance.getFieldProps('comments')}
                  rows={4}
                  style={{ resize: 'none' }}
                ></textarea>
              </div>

              <div className="fs-7 text-muted mb-15">
                Tenga en cuenta que todos los cambios de saldo manuales serán
                auditados por el equipo financiero. Por favor conserve sus
                facturas y recibos hasta entonces. Gracias.
              </div>

              <div className="text-center">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => {
                    formBalance.resetForm();
                    handleClose(false);
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && (
                    <span className="indicator-label">Aprovisionar</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { AprovisionarModal };
