import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export const getAll = async (filters: any) => {
    const response = await axios.get(
      `${API_URL}catalogos/portability`
    );
    return response.data.doc.data;
};
export  const sendPortability = async(values: any) => {
  const { id, fecha_portabilidad, nip, phoneFormat} = values;
  const response = await axios.post(`${API_URL}operation/portability/send`, {
    nip: nip,
    numero_telefono: phoneFormat,
    fecha_portabilidad: fecha_portabilidad,
    portabilidadId: id
  });
  return response;
}

export const confirmStatus = async(id: number, statusId: number) => {
  const response = await axios.post(`${API_URL}catalogos/portability/changeStatus/`,{
    id: id,
    statusId: statusId
  });
  return response;
}






