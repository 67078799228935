import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getAll(
  filters: any
) {
  const response = await axios.get(
    `${API_URL}catalogos/partner`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getAllInactive(
  filters: any
) {
  const response = await axios.get(
    `${API_URL}catalogos/partner/inactive`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getOneSocios(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/partner/${id_row}`);
  const { partner, id, alias, first_name, father_last_name, mother_last_name, email, phone, curp, rfc, birthdate, active, gender } = response.data.doc.data;
  return {
    id: partner.id || 0,
    name: partner.name || '',
    business_name: partner.business_name || '',
    code: partner.code || '',
    commission: partner.commission || 0,
    manager: {
      id: id || 0,
      alias: alias || '',
      first_name: first_name || '',
      father_last_name: father_last_name || '',
      mother_last_name: mother_last_name || '',
      email: email || '',
      phone: maskPhoneNumber(phone) || '',
      phoneFormat: cleanPhoneNumber(phone) || '',
      curp: curp || '',
      rfc: rfc || '',
      birthdate: birthdate || new Date(),
      active: active || false,
      gender: {
        id: gender.id || 0,
        code: gender.code || '',
        name: gender.name || '',
      },
    }
  };
}

export async function addDistribuidor(values: any) {
  let body = values;
  if (body.user.phone) {
    body.user.phone = body.user.phoneFormat;
    body.type_commission_id = body.account.typeCommission.id;
    body.interbank_key = body.clabe;
  }
  let formData = new FormData();
  formData.append('json', JSON.stringify(body));
  formData.append('pdfFile', body.file);
  const response = await axios({
    method: "post",
    url: `${API_URL}catalogos/partner/`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}
export async function updateSocios(body: any) {

  const { id: partner_id, name, business_name, commission, manager: { id: user_id, email, first_name, father_last_name, mother_last_name, phoneFormat, curp, rfc, birthdate, gender } } = body;
  const data = {
    "name": name,
    "business_name": business_name,
    "commission": commission,
    "manager": {
      "email": email,
      "first_name": first_name,
      "father_last_name": father_last_name,
      "mother_last_name": mother_last_name,
      "phone": phoneFormat,
      "curp": curp,
      "rfc": rfc,
      "birthdate": birthdate,
      "gender_id": (gender.id === 0) ? null : gender.id,
    }
  };
  const response = await axios.put(`${API_URL}catalogos/partner/${partner_id}/user/${user_id}`, data);
  return response;
}

export async function removeSocio(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/partner/${id}`);
  return response;
}


export async function activeDistribuidor(values: any) {

  const { id, stripe_id } = values;
  const response = await axios.put(`${API_URL}catalogos/partner/active`, {
    distributor_id: id,
    stripe_id: stripe_id
  });
  return response;
}

export async function getAllDistributorsForReport() {

  const response = await axios.get(
    `${API_URL}catalogos/partner/report/list`
  );
  const data = response.data.doc.data.rows;
  return data;
}

