import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export const getAll = async (filters: any) => {
    const response = await axios.get(
      `${API_URL}catalogos/recharge`
    );
    return response.data.doc.data;

};
export const createRecharge = async (values: any) => {
  const { phoneFormat, offer_id } = values;
    const response = await axios.post(`${API_URL}operation/recharge/`, {
      numero_telefono: phoneFormat,
      offer_id: Number(offer_id),
    });
    return response;
}