import React, { useState, useRef } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
  toAbsoluteUrl,
} from '../../../../_mexablock/helpers';
import { NumericFormat } from 'react-number-format';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  activeDistribuidor,
  // updateEmpresas,
} from '../../../service/distribuidores/Distribuidores';
import { useGetOne } from '../hooks/DistribuidorHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  id_row: number;
};
const DistribuidorSchema = Yup.object().shape({
  stripe_id: Yup.string()
    .min(21, 'Mínimo 21 caracteres')
    .max(21, 'Máximo 21 caracteres')
    .required('Requerido.'),
});

const ActiveModal: React.FC<Props> = ({ id_row, show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const formDistribuidor = useFormik({
    initialValues: initValues,
    validationSchema: DistribuidorSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          activeDistribuidor(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formDistribuidor.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={show}
      onHide={() => {
        formDistribuidor.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              formDistribuidor.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Activar distribuidor</h1>
            <div className="text-muted fw-semibold fs-5">
              Para poder activar el distribuidor es necesario crear el ID en
              Stripe.
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formDistribuidor.handleSubmit}
          >
            <div className="row g-9 mb-5">
              <div className="col-md-12 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className={`fw-bold fs-6 mb-2 w-auto required`}>
                    ID Stripe
                  </label>
                  {formDistribuidor.touched.stripe_id &&
                    formDistribuidor.errors.stripe_id && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.stripe_id}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="ID Stripe"
                  {...formDistribuidor.getFieldProps('stripe_id')}
                  className="form-control"
                  type="text"
                  maxLength={21}
                  name="stripe_id"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formDistribuidor.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { ActiveModal };
