import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_mexablock/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Reportes',
    path: '/reportes/comisiones',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const ReporteComisionesPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Comisiones</PageTitle>
      <View />
    </>
  );
};

export { ReporteComisionesPage };
