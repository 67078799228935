/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import {
  // getCSS,
  getCSSVariableValue,
} from '../../../../_mexablock/assets/ts/_utils';
import { formatPrice } from '../../../../_mexablock/helpers';

type Props = {
  className: string;
  height: number;
  series: any[];
  colors: any[];
  show: boolean;
};

const GraficaTransacciones: React.FC<Props> = ({
  className,
  height,
  series,
  colors,
  show,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (show) {
      if (!chartRef.current) {
        return;
      }
      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(height, series, colors)
      );
      if (chart) {
        chart.render();
      }

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [chartRef, height, series, colors, show]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">
            Saldo consumido por mes
          </span>

          <span className="text-muted fw-bold fs-7">
            Se muestra el saldo consumido en recargas realizadas hasta el mes
            actual.
          </span>
        </h3>
      </div>
      <div className="card-body">
        <div ref={chartRef} id="kt_charts_widget_3_chart"></div>
      </div>
    </div>
  );
};

export { GraficaTransacciones };

function getChartOptions(
  height: number,
  _series: any | null,
  _colors: any
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-primary');

  return {
    series: _series,
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 2,
      colors: _colors,
    },
    xaxis: {
      categories: [
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          return formatPrice(val, 'es-MX', 'MXN', 2, 2);
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return formatPrice(val, 'es-MX', 'MXN', 2, 2);
        },
      },
    },
    // colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      // strokeColors: baseColor,
      strokeWidth: 3,
    },
  };
}
