import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_mexablock/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const CuentasPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Aprovisionamiento</PageTitle>
      <View />
    </>
  );
};

export { CuentasPage };
