import { useState, useEffect } from 'react'
// import { getTestReport } from '../../../../service/reportes/Activaciones'
import { ReportPartnerModel } from '../models/ReportPortabilidadModel'
import { getPortability, getSelectTypes } from '../../../../service/reportes/Portability';
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, filters:any ) => {

    const [ data, setData ] = useState<ReportPartnerModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getPortability( filters ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ filters,  realoadGrid ]);
    return { data, loadingRequest }

}


export const useSelectTypes = ( show: boolean, showFilterCode: boolean ) => {

    const [data, setData] = useState<any[]>([]);
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const res = await getSelectTypes()
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setData(res);
        }
      };
      if (show && showFilterCode) {
        fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, showFilterCode]);
    return { data };
  }