import axios from 'axios'
import { toAbsoluteUrl } from '../../../_mexablock/helpers'
const API_URL = process.env.REACT_APP_API
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}users/`

const sumDayDate = (date: string) => {
    let fechaInicial = new Date(date);
    fechaInicial.setDate(fechaInicial.getDate() + 1);
    let fechaFormateada = fechaInicial.toISOString().slice(0, 10);
    return fechaFormateada;
}

export async function getUsers(startIndex: number, endIndex: number) {

    const response = await axios.get(`${API_URL}users?limit=${startIndex}&page=${endIndex}`);
    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}
export async function getAllMovimientos() {
    const response = await axios.get(
        `${API_URL}catalogos/account/me/movements`
    );
    const data = response.data.doc.data;
    return data;
}

export async function getUser(id: number) {

    const response = await axios.get(`${API_URL}users?id=${id}`);
    const data = response.data.doc.data.rows[0];
    return data;

}

export async function addUser(body: any) {

    const { email, first_name, father_last_name, mother_last_name, roles } = body;
    const data = {
        email: email,
        first_name: first_name,
        father_last_name: father_last_name,
        mother_last_name: mother_last_name,
        role_id: roles[0].id
    }
    const response = await axios.post(`${API_URL}users/`, data);
    return response;

}
export async function updateUser(body: any) {

    const { id, first_name, father_last_name, mother_last_name } = body;
    const data = {
        first_name: first_name,
        father_last_name: father_last_name,
        mother_last_name: mother_last_name,
    }
    const response = await axios.put(`${API_URL}users/${id}`, data);
    return response;

}
export async function activeUser(body: any) {

    const { id, active } = body;
    const data = {
        active: active
    }
    const response = await axios.put(`${API_URL}users/${id}`, data);
    return response;

}
export async function getRoles() {

    const response = await axios.get(`${API_URL}users/roles`);
    const data = response.data.doc.data.rows;
    return data;

}
export async function getMe() {

    const response = await axios.get(`${API_URL}users/me`);
    const random = Math.random() * 40;
    const {
        doc: {
            data: { id, company, first_name, father_last_name, mother_last_name, alias, email, birthdate, phone, roles, profile_photo, active }
        }
    } = response.data;

    const user = {
        id: id,
        alias: alias || '',
        company: company.name,
        email: email,
        father_last_name: father_last_name,
        first_name: first_name,
        mother_last_name: mother_last_name,
        phone: phone || '',
        roles: roles || [],
        active: active,
        birthdate: birthdate,
        profile_photo: profile_photo ? `${API_URL_IMAGE_PUBLIC}${profile_photo}?${random}` : toAbsoluteUrl('/media/avatars/blank.png'),
    }
    return user;

}
export async function updateMe(body: any) {

    const { mother_last_name, alias, avatar } = body;
    let formData = new FormData();
    formData.append('alias', alias);
    formData.append('mother_last_name', mother_last_name);
    formData.append('profile_photo', avatar);

    const response = await axios({
        method: "put",
        url: `${API_URL}users/me`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;

}

export async function resetPassword(body: any) {
    const { currentPassword: current_password, newPassword: password, passwordConfirmation: confirm_password } = body;
    const data = {
        password,
        confirm_password,
        current_password
    }
    const response = await axios.put(`${API_URL}users/pwd`, data);
    return response;
}

export async function addRol(body: any) {

    const { roleId, id } = body;
    const response = await axios.post(`${API_URL}users/${id}/roles/${roleId}`);
    return response;

}
export async function removeRol(body: any) {

    const { roleId, id } = body;
    const response = await axios.delete(`${API_URL}users/${id}/roles/${roleId}`);
    return response;

}
export async function getSelectUsuarios() {

    const response = await axios.get(`${API_URL}users`);
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach((row: any) => {
        row.label = `${row.first_name} ${row.father_last_name}`
        row.value = row.id;
    });
    return dataSelect;

}

export async function getNotificationsUsers(
    filters: any
) {
    let search: string = '';
    if (filters.offer_id !== 0) {
        search += `&offer_id=${filters.offer_id}`;
    }
    if (filters.init_date) {
        search += `&start_date=${filters.init_date}`;
    }
    if (filters.end_date) {
        search += `&end_date=${sumDayDate(filters.end_date)}`;
    }
    const response = await axios.get(
        `${API_URL}catalogos/recharge/customer/noRecharge?sort=id${search}`
    );
    const data = response.data.doc.data;
    return data;
}


export async function sendCampania(filters: any) {
    const { offer_id, init_date, end_date, title, description } = filters;
    const response = await axios.post(`${API_URL}firebaseMessaging/multiple`, {
        offer_id: offer_id,
        start_date: init_date,
        end_date: sumDayDate(end_date),
        title: title,
        message: description
    });
    return response;
}

export async function getAllNotifications() {
    const response = await axios.get(
        `${API_URL}firebaseMessaging/all`
    );
    const data = response.data.doc.data;
    return data;
}


export async function createPass(body: any) {
    const { password, confirmPassword, secretKey } = body;
    const data = {
        password,
        confirmPassword,
        secretKey
    }
    const response = await axios.post(`${API_URL}users/create/pass`, data);
    return response;
}