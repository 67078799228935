import React, { useState, useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../../_mexablock/helpers';

const View: React.FC = () => {
  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-body py-3 mt-5">
              <div className="mb-17">
                <div className="d-flex flex-stack mb-5">
                  <h3 className="text-gray-900">Video Tutoriales</h3>
                </div>

                <div className="separator separator-dashed mb-9"></div>

                <div className="row g-10">
                  <div className="col-md-6">
                    <div className="card-xl-stretch me-md-6">
                      <video
                        width="100%"
                        height="360"
                        controls
                        className=" card-rounded mb-5"
                      >
                        <source
                          src={toAbsoluteUrl('/media/videos/IOS.mp4')}
                          type="video/mp4"
                        />
                        Tu navegador no soporta la reproducción de videos MP4.
                      </video>
                      <div className="m-0">
                        <a
                          href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"
                          className="fs-4 text-gray-900 fw-bold text-hover-primary text-gray-900 lh-base"
                        >
                          Configuración dispositivos IOS{' '}
                        </a>

                        <div className="fw-semibold fs-5 text-gray-600 text-gray-900 my-4">
                          ¡Descubre cómo configurar tu dispositivo Apple para
                          una experiencia de conectividad perfecta con nuestra
                          red en este tutorial práctico y sencillo!
                        </div>

                        <div className="fs-6 fw-bold">
                          <span className="text-muted">
                            Si tienes alguna duda comunicate al centro de
                            soporte:
                          </span>

                          <a
                            href="#/"
                            className="text-gray-700 text-hover-primary "
                          >
                            {' '}
                            811 778 6534
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card-xl-stretch mx-md-3">
                      <video
                        width="100%"
                        height="360"
                        controls
                        className=" card-rounded mb-5"
                      >
                        <source
                          src={toAbsoluteUrl('/media/videos/ANDROID.mp4')}
                          type="video/mp4"
                        />
                        Tu navegador no soporta la reproducción de videos MP4.
                      </video>
                      <div className="m-0">
                        <a
                          href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"
                          className="fs-4 text-gray-900 fw-bold text-hover-primary text-gray-900 lh-base"
                        >
                          Configuración dispositivos Android{' '}
                        </a>

                        <div className="fw-semibold fs-5 text-gray-600 text-gray-900 my-4">
                          ¡Descubre cómo configurar tu dispositivo Android para
                          una experiencia de conectividad perfecta con nuestra
                          red en este tutorial práctico y sencillo!
                        </div>

                        <div className="fs-6 fw-bold">
                          <span className="text-muted">
                            Si tienes alguna duda comunicate al centro de
                            soporte:
                          </span>

                          <a
                            href="#/"
                            className="text-gray-700 text-hover-primary "
                          >
                            {' '}
                            811 778 6534
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
