import { useState, useEffect } from 'react';
import {
  getAll
} from '../../../service/callcenter/CallCenter';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<UserModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetOne = (id: number) => {


  const currentDate = new Date();
  const fechaMaxima = new Date(currentDate);
  fechaMaxima.setFullYear(currentDate.getFullYear() - 18);

  const initModel = {
      id: 0,
      alias: "",
      first_name: "",
      father_last_name: "",
      mother_last_name: "",
      email: "",
      phone: "",
      phoneFormat: "",
      birthdate: fechaMaxima,
      active: false,
      gender: {
        id: 0,
        code: 'SEL',
        name: 'Seleccione'
      }
  };
  const [initValues, setInitValues] = useState<UserModel>(initModel);
  // const history = useHistory();
  // useEffect(() => {
  //   const fetchPost = async () => {
  //     const res = await getOneSocios(id)
  //       .then()
  //       .catch((error) => {
  //         history.push('/error/500');
  //       });
  //     if (res) {
  //       setInitValues(res);
  //     }
  //   };
  //   if (id) {
  //     fetchPost();
  //   } else {
  //     setInitValues(initModel);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);
  return { initValues };
};