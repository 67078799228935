import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  userHasRole,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
  toAbsoluteUrl,
  formatPrice,
} from '../../../../_mexablock/helpers';
import * as auth from '../../../modules/auth/redux/AuthRedux';
import clsx from 'clsx';
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepperComponent } from '../../../../_mexablock/assets/ts/components';
import { sendCampania } from '../../../service/users/Usuarios';
import { NotificationModel } from '../models/NotificationModel';
import { useSelectOffers } from '../../operacion/activacion/hooks/ActivacionHook';
import { useGetAll } from '../hooks/NotificationsHook';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};

const schemaNotifications = [
  Yup.object({
    title: Yup.string().required('Requerido.').min(10, 'Mínimo 10 caracteres.'),
    description: Yup.string()
      .required('Requerido.')
      .min(10, 'Mínimo 10 caracteres.'),
  }),
  Yup.object({
    // offer_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
    init_date: Yup.date().required('Requerido.'),
    end_date: Yup.date().required('Requerido.'),
  }),
];

const initValues: NotificationModel = {
  title: '',
  description: '',
  offer_id: 0,
  init_date: new Date().toISOString().split('T')[0],
  end_date: new Date().toISOString().split('T')[0],
};

const NotificationsModal: React.FC<Props> = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [validateBack, setValidateBack] = useState<boolean>(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { data: offers } = useSelectOffers(show, 'recharge');

  // STEPPER
  const [currentSchema, setCurrentSchema] = useState(schemaNotifications[0]);
  const [textStepper, setTextStepper] = useState('Continuar');
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (show) {
      loadStepper();
    }
  }, [stepperRef, show]);

  const formNotifications = useFormik({
    initialValues: initValues,
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            if (!stepper.current) {
              return;
            }
            stepper.current.goNext();
            setCurrentSchema(schemaNotifications[1]);
            setTextStepper('Enviar campaña');
            setReloadGrid(Math.random() * 40);
            setLoading(false);
          }, 1000);

          break;
        case 2:
          setLoading(true);
          setTimeout(() => {
            sendCampania(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                stepper.current.goNext();
                setTextStepper('Finalizar');
                setValidateBack(false);
                setLoading(false);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details },
                } = err.response;
                resMessageToast = `${message}`;
                if (details) {
                  resMessageToast = `${details[0].message}`;
                }
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;
        case 3:
          if (!stepper.current) {
            return;
          }
          handleClose(true);
          stepper.current.goto(1);
          setTextStepper('Continuar');
          setCurrentSchema(schemaNotifications[0]);
          formNotifications.resetForm();
          setLoading(false);
          break;
      }
    },
  });

  const { count } = useGetAll(show, reloadGrid, formNotifications.values);

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(schemaNotifications[stepper.current.currentStepIndex - 1]);
    switch (stepper.current.currentStepIndex - 1) {
      case 1:
        setTextStepper('Continuar');
        break;
      case 2:
        setTextStepper('Continuar');
        break;
      default:
        setTextStepper('Continuar');
        break;
    }
  };

  return (
    <Modal
      id="kt_modal_new_recharge"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        stepper?.current?.goto(1);
        formNotifications.resetForm();
        setCurrentSchema(schemaNotifications[0]);
        setTextStepper('Continuar');
        setValidateBack(true);
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              stepper?.current?.goto(1);
              formNotifications.resetForm();
              setCurrentSchema(schemaNotifications[0]);
              setTextStepper('Continuar');
              setValidateBack(true);
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Nueva campaña</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Detalle de la notificación</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Audiencia</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Completada</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-800px pt-10 pb-10"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formNotifications.handleSubmit}
            >
              <div
                className="current min-h-350px"
                data-kt-stepper-element="content"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Título
                      </label>
                      {formNotifications.touched.title &&
                        formNotifications.errors.title && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNotifications.errors.title}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      className="form-control mb-3"
                      {...formNotifications.getFieldProps('title')}
                      placeholder="Título de la notificación"
                      prefix=""
                    />
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Descripción
                      </label>
                      {formNotifications.touched.description &&
                        formNotifications.errors.description && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNotifications.errors.description}
                            </span>
                          </div>
                        )}
                    </div>
                    <textarea
                      className="form-control rounded-3 mb-5"
                      {...formNotifications.getFieldProps('description')}
                      rows={4}
                      placeholder="Descripción de la notificación"
                      style={{ resize: 'none' }}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-350px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Fecha inicio
                          </label>
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            new Date(formNotifications.values.init_date)
                              .toISOString()
                              .split('T')[0]
                          }
                          onChange={(e) => {
                            if (e.target.value) {
                              const fecha = new Date(e.target.value)
                                .toISOString()
                                .split('T')[0];
                              formNotifications.setFieldValue(
                                'init_date',
                                fecha
                              );
                              setReloadGrid(Math.random() * 40);
                            }
                          }}
                        ></input>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Fecha fin
                          </label>
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            new Date(formNotifications.values.end_date)
                              .toISOString()
                              .split('T')[0]
                          }
                          onChange={(e) => {
                            if (e.target.value) {
                              const fecha = new Date(e.target.value)
                                .toISOString()
                                .split('T')[0];
                              formNotifications.setFieldValue(
                                'end_date',
                                fecha
                              );
                              setReloadGrid(Math.random() * 40);
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Seleciona una oferta
                      </label>
                      {formNotifications.touched.offer_id &&
                        formNotifications.errors.offer_id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNotifications.errors.offer_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select"
                      onChange={(e: any) => {
                        const id = e.target.value;
                        formNotifications.setFieldValue('offer_id', Number(id));
                        setReloadGrid(Math.random() * 40);
                      }}
                    >
                      <option value={0}>Seleccione</option>
                      {offers.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100">
                      <div className="pb-5 pb-lg-10">
                        <div className="text-muted fw-semibold fs-6">
                          Está es la cantidad de usuarios a los que se les
                          enviará la notificación.
                        </div>
                      </div>
                      <div className="d-flex flex-column mb-10 ">
                        <div className="d-flex align-items-center gap-5">
                          <div className="symbol symbol-50px symbol-lg-50px symbol-fixed position-relative">
                            <img
                              src={toAbsoluteUrl('/media/avatars/blank.png')}
                              alt="Metornic"
                            />
                          </div>

                          <div className="fw-bold">
                            <span className="fw-bold d-flex align-items-center text-primary fs-4">
                              {count}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-350px"
              >
                <div className="w-100">
                  <div className="text-center">
                    <h1 className="fw-bold text-primary">Campaña exitosa!</h1>

                    <div className="fw-semibold text-muted fs-4">
                      Se envío la notificación a {count} usuarios.
                    </div>
                  </div>

                  <div className="text-center px-4">
                    <img
                      className="mx-auto h-300px h-lg-300px"
                      src={toAbsoluteUrl(
                        '/media/illustrations/sketchy-1/ok.png'
                      )}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {validateBack && (
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Regresar
                    </button>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { NotificationsModal };
