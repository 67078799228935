/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { formatPrice, KTSVG } from '../../../../_mexablock/helpers';

type Props = {
  className: string;
  data: any;
};

const Offers: React.FC<Props> = ({ className, data }) => {
  return (
    <>
      <div className={`card card card-flush h-xl-100`}>
        <div className="card-body">
          <div className="text-center">
            <span className="fw-bold fs-1 d-block text-hover-primary text-gray-800">
              {`${data.gb} GB`}
            </span>
            <span className="fw-semibold fs-3 text-gray-600">
              {`${formatPrice(data.price, 'es-MX', 'MXN', 2, 2)}`}
            </span>
            <span className="fw-semibold fs-7 text-gray-500">
              {` / ${data.commercial_time}`}
            </span>
          </div>

          <div className="fw-semibold fs-5 mt-1 text-gray-600 text-center">
            {data.share_internet ? (
              <KTSVG
                className="svg-icon-3 svg-icon-success"
                path="/media/icons/duotune/arrows/arr016.svg"
              />
            ) : (
              <KTSVG
                className="svg-icon-3"
                path="/media/icons/duotune/arrows/arr015.svg"
              />
            )}
            Comparte internet
          </div>
          <div className=" fw-semibold fs-5  mt-1 text-gray-600 text-center">
            <KTSVG
              className="svg-icon-3"
              path="/media/icons/duotune/communication/com013.svg"
            />
            {`${data.minutes} Minutos`}
          </div>
          <div className=" fw-semibold fs-5 mt-1 text-gray-600 text-center">
            <KTSVG
              className="svg-icon-3"
              path="/media/icons/duotune/communication/com003.svg"
            />
            {`${data.sms} SMS`}
          </div>
        </div>
      </div>
    </>
  );
};

export { Offers };
