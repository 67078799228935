import React, { useState, useEffect } from 'react';
import { SaldosEmpresas } from './SaldosEmpresas';
import { GraficaTransacciones } from './GraficaTransacciones';
// import { AsignarSaldo } from './AsignarSaldo';
import { BannerSaldo } from './BannerSaldo';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../../_mexablock/helpers';
import { useSelectCuentas } from '../hooks/DashboardHook';
import { TopUpBalanceModal } from '../../../../_mexablock/partials/modals/balance/TopUpBalanceModal';
import { Offers } from './Offers';
import { useSelectOffers } from '../../operacion/activacion/hooks/ActivacionHook';

// import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils';

const DashboardPage: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  const [rol] = useState(userRoles ? userRoles[0].code : '');
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid] = useState(Math.random() * 40);
  const { data, count, loadingRequest } = useSelectCuentas(reloadGrid);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [dataCharts, setDataCharts] = useState([]);
  const [dataColors, setDataColors] = useState([]);
  const [show, setShow] = useState(false);
  const { data: offers } = useSelectOffers(true, 'recharge');

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    if (count > 0) {
      const dataEffect: any = [];
      const colorsEffect: any = [];
      data.forEach((element: any) => {
        const colors = getRandomHexColor();
        colorsEffect.push(colors);
        dataEffect.push({
          name: element.distributor
            ? element.distributor.first_name
            : element.company.name,
          color: colors,
          data: [
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
          ],
        });
      });
      setDataCharts(dataEffect);
      setDataColors(colorsEffect);
      setShow(true);
    }
  }, [count, data]);

  const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const getRandomNumber = () => {
    return Math.floor(Math.random() * 10000);
  };

  return (
    <>
      <div className="row g-6 g-xl-9">
        <h2>MIS PLANES</h2>
        {offers.map((item: any, index: number) => (
          <div className="col-xl-3">
            <Offers data={item} className="card card-flush h-xl-100" />
          </div>
        ))}
        {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) &&
          dataCharts.length > 0 && (
            <div className="col-xl-8">
              <GraficaTransacciones
                series={dataCharts}
                colors={dataColors}
                show={show}
                height={350}
                className="card card-flush h-xl-100"
              />
            </div>
          )}
        {/* {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) && (
          <div className="col-xl-4">
            <AsignarSaldo
              data={data}
              tipoRole={rol}
              callback={(updateData: boolean) => {
                if (updateData) {
                  setReloadGrid(Math.random() * 40);
                }
              }}
            />
          </div>
        )} */}
        {userHasRole(
          ['ADMIN_COMPANY', 'ADMIN_PARTNER', 'DISTRIBUTOR'],
          userRoles
        ) && (
          <div className="col-xl-4">
            <BannerSaldo
              data={{}}
              callback={() => {
                setVisivilityModal(!visivilityModal);
              }}
            />
          </div>
        )}
        {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) && (
          <div className="col-xl-4">
            <SaldosEmpresas
              data={data}
              loadingGrid={loadingGrid}
              tipoRole={rol}
            />
          </div>
        )}
      </div>
      <TopUpBalanceModal
        show={visivilityModal}
        handleClose={(updateData: boolean) => {
          setVisivilityModal(!visivilityModal);
        }}
      />
    </>
  );
};

export { DashboardPage };
