import { useState, useEffect } from 'react';
import {
  getAllEmpresas,
  getOneEmpresas,
  getSelectEmpresas,
} from '../../../service/empresas/Empresas';
import { EmpresaModel} from '../models/EmpresaModel';
import { useHistory } from 'react-router-dom';

export const useSelectEmpresas = () => {
  const [empresas, setEmpresas] = useState<EmpresaModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getSelectEmpresas()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setEmpresas(res);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { empresas };
};

export const useGetAll = (
  realoadGrid: number,
  filters: any,
) => {
  const [data, setData] = useState<EmpresaModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllEmpresas(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid, filters]);
  return { data, loadingRequest };
};

export const useGetOne = (id: number) => {

  const currentDate = new Date();
  const fechaMaxima = new Date(currentDate);
  fechaMaxima.setFullYear(currentDate.getFullYear() - 18); // Restar 18 años
  // const maxDate = fechaMaxima.toISOString().split('T')[0];

  const initModel = {
    id: 0,
    name: '',
    code: '',
    business_name: '',
    email: "",
    active: true,
    cfdi: {
      id: 14,
      code: 'G01',
      name: 'Adquisición de mercancías',
    },
    type: {
      id: 12,
      code: 'EMP',
      name: 'Empresa',
    },
    account: {
      id: 0,
      typeCommission: {
          id: 0,
          code: "",
          name: ""
      }
    },
    created_at:  new Date(),
    rfc: "",
    constancy: '',
    file: '',
    clabe: '',
    stripe_id: '',
    user: {
      id: 0,
      alias: "",
      first_name: "",
      father_last_name: "",
      mother_last_name: "",
      email: "",
      phone: "",
      phoneFormat: "",
      // curp: "",
      birthdate: fechaMaxima,
      active: false,
      gender: {
        id: 0,
        code: 'SEL',
        name: 'Seleccione'
      }
    }
  };

  const [initValues, setInitValues] = useState<EmpresaModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOneEmpresas(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};


export const useGetEmpresaById = (id: number) => {


  const history = useHistory();

    const[data, setData] = useState<any>();

    useEffect(()=> {
    const fetchPost = async () => {
      const res:any = await getOneEmpresas(id)
        .then( res => res.json())
      //   .then( (res: Response) =>  {
      //     setData(res);
      //  })
        .catch((error) => {
          history.push('/error/500');
        });
    };
    setData(fetchPost);
    },[]);

    return {data};

};

