import React, { useState, useEffect } from 'react';
import {
  KTSVG,
  LoadingGrid,
  toAbsoluteUrl,
  formatDate,
  maskPhoneNumber,
} from '../../../../_mexablock/helpers';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { useGetAllNotifications } from '../hooks/NotificationsHook';
import { NotificationsModal } from '../_modals/NotificationsModal';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { data, loadingRequest } = useGetAllNotifications(reloadGrid);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setVisivilityModal(!visivilityModal);
  };

  const columns: any = [
    {
      id: 'title',
      name: <div className="fw-bolder text-muted me-5">Título</div>,
      cell: (row: any) => row.title,
      sortable: true,
      width: '30%',
    },
    {
      id: 'body',
      name: <div className="fw-bolder text-muted me-5">Descripción</div>,
      cell: (row: any) => row.body,
      sortable: true,
      width: '30%',
    },
    {
      id: 'users',
      name: <div className="fw-bolder text-muted me-5">Audiencia</div>,
      cell: (row: any) => row.total_user,
      sortable: true,
      width: '20%',
    },
    {
      id: 'fecha',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => row.createdAt,
      cell: (row: any) => formatDate(row.createdAt),
      sortable: true,
      width: '20%',
    },
    // {
    //   id: 'correo',
    //   name: <div className="fw-bolder text-muted me-5">Correo</div>,
    //   selector: (row: any) => row.email,
    //   sortable: true,
    //   width: '25%',
    // },
    // {
    //   id: 'telefono',
    //   name: <div className="fw-bolder text-muted me-5">Teléfono</div>,
    //   selector: (row: any) => maskPhoneNumber(row.phone),
    //   sortable: true,
    //   width: '15%',
    // },
    // {
    //   id: 'correo',
    //   name: <div className="fw-bolder text-muted me-5">Correo</div>,
    //   selector: (row: any) => row.user.email,
    //   sortable: true,
    //   width: '25%',
    // },
    // {
    //   id: 'acciones',
    //   name: <div className="fw-bolder text-muted me-5"></div>,
    //   cell: (row: any) => dropdownRender(row.id),
    //   sortable: false,
    //   width: '10%',
    // },
  ];

  const imageRender = (user: any) => {
    return (
      <>
        <div className="symbol symbol-40px me-5">
          <img
            src={
              user.profile_photo
                ? `${process.env.REACT_APP_PUBLIC}users/${user.profile_photo}`
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            crossOrigin="anonymous"
            className=""
            alt=""
          />
        </div>
      </>
    );
  };
  const dropdownRender = (id: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
          </a>

          {/* <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  onEditRegister(id);
                }}
              >
                Editar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  onRemoveRegister(id);
                }}
              >
                Eliminar
              </a>
            </div>
          </div> */}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              {/* <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              /> */}
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addRegister}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nueva campaña
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <NotificationsModal
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></NotificationsModal>
    </>
  );
};

export { View };
