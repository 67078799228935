import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getModeApp } from '../../../../../app/service/header/Header';


export const useGetModeApp = () => {
    const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');
    const [isEnable, setIsEnable] = useState<boolean>();

    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getModeApp()
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setIsEnable(res.value);
            }
        };
        fetchPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        backgroundColor,
        isEnable
    }

}