import { useState, useEffect } from 'react';
import {
  getAllClientes,
  getOneClientes,
  getSelectClientes
} from '../../../service/clientes/Clientes';
import { ClienteModel } from '../models/ClienteModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  filters: any
) => {
  const [data, setData] = useState<ClienteModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllClientes(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
      fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  return { data, loadingRequest};
};

// export const useGetOne = (id: number) => {
//   const initModel = {
//       id: 0,
//       first_name: "",
//       father_last_name: "",
//       mother_last_name: "",
//       email: "",
//       phone: "",
//       phoneFormat: "",
//       verified_email: false
//   };
//   const [initValues, setInitValues] = useState<ClienteModel>(initModel);
//   const history = useHistory();
//   useEffect(() => {
//     const fetchPost = async () => {
//       const res = await getOneClientes(id)
//         .then()
//         .catch((error) => {
//           history.push('/error/500');
//         });
//       if (res) {
//         setInitValues(res);
//       }
//     };
//     if (id) {
//       fetchPost();
//     } else {
//       setInitValues(initModel);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [id]);
//   return { initValues };
// };

export const useSelectClients = ( show:boolean ) => {

  const [ data, setData ] = useState<ClienteModel[]>([]);
  const history = useHistory()
  useEffect(() => {
      const fetchPost = async () => {
          const res = await getSelectClientes().then().catch((error) => {
              history.push('/error/500')
          });
          if( res ){
            setData(res);
          }
       };
       if(show){
        fetchPost();
       }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return { data }
}