import { useState, useEffect } from 'react';
import {
  getAll,
  getOneSocios
} from '../../../service/distribuidores/Distribuidores';
import { DistribuidorModel } from '../models/DistribuidorModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<DistribuidorModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetOne = (id: number) => {


  const currentDate = new Date();
  const fechaMaxima = new Date(currentDate);
  fechaMaxima.setFullYear(currentDate.getFullYear() - 18);

  const initModel = {
    id: 0,
    name: '',
    code: '',
    business_name: '',
    email: "",
    active: true,
    cfdi: {
      id: 0,
      code: 'SEL',
      name: 'Seleccione',
    },
    account: {
      id: 0,
      typeCommission: {
          id: 0,
          code: "",
          name: ""
      }
    },
    created_at:  new Date(),
    rfc: "",
    constancy: '',
    file: '',
    clabe: '',
    stripe_id: '',
    user: {
      id: 0,
      alias: "",
      first_name: "",
      father_last_name: "",
      mother_last_name: "",
      email: "",
      phone: "",
      phoneFormat: "",
      // curp: "",
      birthdate: fechaMaxima,
      active: false,
      gender: {
        id: 0,
        code: 'SEL',
        name: 'Seleccione'
      }
    }
  };
  const [initValues, setInitValues] = useState<DistribuidorModel>(initModel);
  // const history = useHistory();
  // useEffect(() => {
  //   const fetchPost = async () => {
  //     const res = await getOneSocios(id)
  //       .then()
  //       .catch((error) => {
  //         history.push('/error/500');
  //       });
  //     if (res) {
  //       setInitValues(res);
  //     }
  //   };
  //   if (id) {
  //     fetchPost();
  //   } else {
  //     setInitValues(initModel);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);
  return { initValues };
};