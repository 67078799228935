// useApi.ts
import { useState, useEffect } from 'react';
import { getAll } from '../../../../service/operacion/Portabilidad';
import { PortabilidadModel } from '../models/PortabilidadModel';
import { useHistory } from 'react-router-dom';

const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<PortabilidadModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    setLoadingRequest(0);
    const fetchPost = async () => {
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, realoadGrid]);
  return { data, loadingRequest };
};

// const useGetOne = (id: number) => {
//   const initModel = {
//     id: id,
//     phone: '',
//     phoneFormat: '',
//     nip: '',
//     fecha_portabilidad: '',
//   };
//   const [initValues, setInitValues] = useState<any>(initModel);
//   useEffect(() => {
//     if (id) {
//       setInitValues(initModel);
//     } 
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [id]);
//   return { initValues };
// };

export {
    useGetAll
};