import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_mexablock/layout/core';
// import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const CambioMSISDNPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Cambios de MSISDN</PageTitle>
      {/* <View /> */}
    </>
  );
};

export { CambioMSISDNPage };
