/* eslint-disable react-hooks/rules-of-hooks */
export const formatCurp = ( ) => {
    
   return 1;
}

export const formatDate = ( date: string) => {
   
   return new Date(date).toISOString().split('T')[0]
}
export const formatPrice = ( price: number, locale: string, currency: string, minDigits: number, maxDigits: number) => {
   return Number(price).toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    })
}
export const maskPhoneNumber = (inputPhoneNumber: string): string => {
   const numericValue = inputPhoneNumber.replace(/[^\d]/g, '');
   let maskedValue = '';
   for (let i = 0; i < numericValue.length && i < 10; i++) {
     if (i === 0) maskedValue += `${numericValue[i]}`;
     else if (i === 2) maskedValue += ` ${numericValue[i]}`;
     else if (i === 6 ) maskedValue += ` ${numericValue[i]}`;
     else maskedValue += numericValue[i];
   }
   return maskedValue;
 };
 export const cleanPhoneNumber = (phoneNumber: string): string => {
   const phone = phoneNumber.replace(/\D/g, '');
   return phone;
 };