import React, { useState } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addCallCenter } from '../../../service/callcenter/CallCenter';
import { useGetOne } from '../hooks/CallcenterHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const CallCenterSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(20, 'Máximo 20 caracteres')
    .required('Requerido.'),

  mother_last_name: Yup.string()
    .max(20, 'Máximo 20 caracteres')
    .required('Requerido.'),
  father_last_name: Yup.string()
    .max(20, 'Máximo 20 caracteres')
    .required('Requerido.'),
  birthdate: Yup.date().required('Requerido.'),
  email: Yup.string()
    .email('Formato inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Requerido.'),
  phoneFormat: Yup.string().required('Requerido.').min(10, 'Formato inválido'),
  gender: Yup.object().shape({
    id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
});

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18);
const maxDate = fechaMaxima.toISOString().split('T')[0];

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { catalog: genders } = useSelectGeneric('GEN');

  const formCallCenter = useFormik({
    initialValues: initValues,
    validationSchema: CallCenterSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          // updateEmpresas(values)
          //   .then((res) => {
          //     const {
          //       data: { message },
          //     } = res;
          //     SimpleAlert(message, 3000, 'success');
          //     formCallCenter.resetForm();
          //     setLoading(false);
          //     handleClose(true);
          //   })
          //   .catch((err) => {
          //     let resMessageToast: string = '';
          //     const {
          //       data: { message, details = null },
          //     } = err.response;
          //     resMessageToast = `${message}`;
          //     if (details.length) {
          //       setStatus(`${details[0]?.message}`);
          //     }
          //     setLoading(false);
          //     setSubmitting(false);
          //     SimpleAlert(resMessageToast, 3000, 'error');
          //   });
        } else {
          addCallCenter(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formCallCenter.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                resMessageToast = `${details[0].message}`;
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formCallCenter.setFieldValue('phone', maskedPhoneNumber);
    formCallCenter.setFieldValue(
      'phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formCallCenter.setFieldValue(key, inputLimpio);
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        formCallCenter.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              formCallCenter.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3"> Nuevo usuario</h1>
            <div className="text-muted fw-semibold fs-5">
              Agrega la información del usuario call center.
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formCallCenter.handleSubmit}
          >
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Nombre
                  </label>
                  {formCallCenter.touched.first_name &&
                    formCallCenter.errors.first_name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formCallCenter.errors.first_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Nombre"
                  {...formCallCenter.getFieldProps('first_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="first_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'first_name');
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Paterno
                  </label>
                  {formCallCenter.touched.father_last_name &&
                    formCallCenter.errors.father_last_name && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formCallCenter.errors.father_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Paterno"
                  {...formCallCenter.getFieldProps('father_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="father_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'father_last_name');
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Materno
                  </label>
                  {formCallCenter.touched.mother_last_name &&
                    formCallCenter.errors.mother_last_name && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formCallCenter.errors.mother_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Materno"
                  {...formCallCenter.getFieldProps('mother_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="mother_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'mother_last_name');
                  }}
                />
              </div>
            </div>
            <div className="row g-9 mb-5">
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Genero
                  </label>
                  {formCallCenter.touched.gender?.id &&
                    formCallCenter.errors.gender?.id && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formCallCenter.errors.gender.id}
                        </span>
                      </div>
                    )}
                </div>
                <select
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formCallCenter.getFieldProps('gender.id')}
                >
                  <option value={0}>Seleccione</option>
                  {genders.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Fecha de nacimiento
                  </label>
                  {formCallCenter.touched.birthdate &&
                    formCallCenter.errors.birthdate && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formCallCenter.errors.birthdate}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  type="date"
                  className="form-control"
                  onKeyDown={(e) => e.preventDefault()}
                  value={
                    new Date(formCallCenter.values.birthdate)
                      .toISOString()
                      .split('T')[0]
                  }
                  max={maxDate}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formCallCenter.setFieldValue('birthdate', fecha);
                    }
                  }}
                ></input>
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Correo
                  </label>
                  {formCallCenter.touched.email && formCallCenter.errors.email && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formCallCenter.errors.email}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Correo"
                  {...formCallCenter.getFieldProps('email')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="email"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Teléfono
                  </label>
                  {formCallCenter.touched.phoneFormat &&
                    formCallCenter.errors.phoneFormat && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formCallCenter.errors.phoneFormat}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  className="form-control mb-3"
                  value={formCallCenter.values.phone}
                  placeholder="Teléfono"
                  prefix=""
                  onChange={handlePhoneChange}
                />
              </div>
            </div>

            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formCallCenter.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
