import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getAllClientes(
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.verified_email !== 0) {
      search += `?verified=${filters.verified_email === 1 ? 1: 0}`;
    }
  }
  const response = await axios.get(
    `${API_URL}catalogos/customer${search}`
  );
  const data = response.data.doc.data;
  return data;
}



export async function sendEmail(id: number) {
  const response = await axios.post(`${API_URL}users/resend/confirm/${id}`);
  return response;
}


export async function getOneClientes(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/customer/${id_row}`);
  const { id, first_name, father_last_name, mother_last_name, email, phone, curp, rfc, birthdate, active, gender } = response.data.doc.data;
  return {
      id: id || 0,
      first_name: first_name || '',
      father_last_name: father_last_name || '',
      mother_last_name: mother_last_name || '',
      email: email || '',
      phone: maskPhoneNumber(phone) || '',
      phoneFormat: cleanPhoneNumber(phone) || '',
      curp: curp || '',
      rfc: rfc || '',
      birthdate: birthdate || new Date(),
      active: active || false,
      gender: {
        id: gender.id || 0,
        code: gender.code || '',
        name: gender.name || '',
      }
    }
}

export async function addClientes(body: any) {
  const { email, first_name, father_last_name, mother_last_name, phoneFormat, curp, rfc, birthdate, gender } = body;
  const data = {
        "email": email,
        "first_name": first_name,
        "father_last_name": father_last_name,
        "mother_last_name": mother_last_name,
        "phone": phoneFormat,
        "curp": curp,
        "rfc": rfc,
        "birthdate": birthdate,
        "gender_id": (gender.id === 0) ? null : gender.id,
  };
  const response = await axios.post(`${API_URL}catalogos/customer`, data);
  return response;
}
export async function updateClientes(body: any) {

  const {  id, email, first_name, father_last_name, mother_last_name, phoneFormat, curp, rfc, birthdate, gender} = body;
  const data = {
        "email": email,
        "first_name": first_name,
        "father_last_name": father_last_name,
        "mother_last_name": mother_last_name,
        "phone": phoneFormat,
        "curp": curp,
        "rfc": rfc,
        "birthdate": birthdate,
        "gender_id": (gender.id === 0) ? null : gender.id,
  };
  const response = await axios.put(`${API_URL}catalogos/customer/${id}`, data);
  return response;
}

export async function removeClientes(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/customer/${id}`);
  return response;
}
export async function getSelectClientes() {

  const response = await axios.get(`${API_URL}catalogos/customer`);
  const data = response.data.doc.data;
  let dataSelect: any = data.rows;
  dataSelect.forEach(( row:any ) => {
      row.label = `${row.first_name} ${row.father_last_name} ${row.mother_last_name} (${row.phone})`
      row.value = row.id;
  });
  return dataSelect;
}