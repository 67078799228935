import { useState, useEffect } from 'react';
import {
  getAllInactive
} from '../../../service/distribuidores/Distribuidores';
import { DistribuidorModel } from '../../distribuidor/models/DistribuidorModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<DistribuidorModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllInactive(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: id,
    stripe_id: '',
  };
  const [initValues, setInitValues] = useState<any>(initModel);
  useEffect(() => {
    if (id) {
      setInitValues(initModel);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};