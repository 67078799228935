import { FC } from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../../_mexablock/assets/json/animation_llqsryhi.json';

const Error404: FC = () => {
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">
        Página no encontrada
      </h1>

      <div className="fw-bold fs-3 text-gray-400">
        ¡La página que buscaste no se encuentra!
      </div>
      <div className="d-flex align-items-center flex-column flex-column-fluid text-center">
        <div className="w-400px">
          <Lottie animationData={animationData} />
        </div>
      </div>
    </>
  );
};
export { Error404 };
