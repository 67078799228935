import { useState, useEffect } from 'react'
// import { getTestReport } from '../../../../service/reportes/Activaciones'
import { ReportActivationsModel } from '../models/ReportActivationsModel'
import { getActivations } from '../../../../service/reportes/Activaciones';
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, filters:any ) => {

    const [ data, setData ] = useState<ReportActivationsModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getActivations( filters ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ filters,  realoadGrid ]);
    return { data, loadingRequest }

}
