import React, { useState } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  addEmpleados,
  updateEmpleados,
} from '../../../service/empleados/Empleados';
import { useGetOne } from '../hooks/EmpleadosHook';
import { NumericFormat } from 'react-number-format';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const EmpleadoSchema = Yup.object().shape({
  employee_number: Yup.string()
    .max(100, 'Máximo 100 caracteres')
    .required('Requerido.'),
  rfc: Yup.string().matches(
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
    'Formato de RFC inválido'
  ),
  manager: Yup.object().shape({
    first_name: Yup.string()
      .max(100, 'Máximo 100 caracteres')
      .required('Nombre requerido.'),
    mother_last_name: Yup.string().max(100, 'Máximo 100 caracteres'),
    father_last_name: Yup.string()
      .max(100, 'Máximo 100 caracteres')
      .required('Apellido requerido.'),
    birthdate: Yup.date().required('Fecha nacimiento requerido.'),
    email: Yup.string()
      .email('Formato de correo inválido.')
      .min(3, 'Mínimo 3 caracteres.')
      .max(50, 'Máximo 50 caracteres.')
      .required('Correo requerido.'),
    phoneFormat: Yup.string()
      .required('Teléfono requerido.')
      .min(10, 'Formato de teléfono inválido'),
    // curp: Yup.string().matches(
    //   /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    //   'Formato de CURP inválido'
    // ),

    gender: Yup.object().shape({
      id: Yup.number()
        .min(1, 'Genero requerido.')
        .required('Genero requerido.'),
    }),
  }),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { catalog: genders } = useSelectGeneric('GEN');

  const formEmpleado = useFormik({
    initialValues: initValues,
    validationSchema: EmpleadoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateEmpleados(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formEmpleado.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addEmpleados(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formEmpleado.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';

              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formEmpleado.setFieldValue('manager.phone', maskedPhoneNumber);
    formEmpleado.setFieldValue(
      'manager.phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        formEmpleado.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              formEmpleado.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">{title}</h1>
            <div className="text-muted fw-semibold fs-5">
              Agrega la información del empleado.
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formEmpleado.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2">
                        Número de empleado
                      </label>
                      {formEmpleado.touched.employee_number &&
                        formEmpleado.errors.employee_number && (
                          <div className="fv-plugins-message-container text-danger ms-5">
                            <span role="alert">
                              {formEmpleado.errors.employee_number}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="employee_number"
                      className="form-control"
                      value={formEmpleado.values.employee_number}
                      decimalScale={0}
                      decimalSeparator="."
                      maxLength={12}
                      fixedDecimalScale
                      placeholder="Número de empleado"
                      prefix=""
                      thousandSeparator=""
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formEmpleado.setFieldValue(
                          'employee_number',
                          floatValue ? floatValue : 0
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-gray-600 mt-7">Datos del empleado</div>
            <div className="separator my-5"></div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Nombre
                    </label>
                    {formEmpleado.touched.manager?.first_name &&
                      formEmpleado.errors.manager?.first_name && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.first_name}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="Nombre del empleado"
                    {...formEmpleado.getFieldProps('manager.first_name')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="manager.first_name"
                    autoComplete="off"
                  />
                </div>
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-40">
                      Apellido Paterno
                    </label>
                    {formEmpleado.touched.manager?.father_last_name &&
                      formEmpleado.errors.manager?.father_last_name && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.father_last_name}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="Apellido Paterno"
                    {...formEmpleado.getFieldProps('manager.father_last_name')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="manager.father_last_name"
                    autoComplete="off"
                  />
                </div>
                <div className="pe-5" style={{ width: '34%' }}>
                  <div className="w-100 d-flex">
                    <label className=" fw-bold fs-6 mb-2 w-40">
                      Apellido Materno
                    </label>
                    {formEmpleado.touched.manager?.mother_last_name &&
                      formEmpleado.errors.manager?.mother_last_name && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.mother_last_name}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="Apellido Materno"
                    {...formEmpleado.getFieldProps('manager.mother_last_name')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="manager.mother_last_name"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
              <div className="w-100 d-flex">
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Genero
                    </label>
                    {formEmpleado.touched.manager?.gender?.id &&
                      formEmpleado.errors.manager?.gender?.id && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.gender?.id}
                          </span>
                        </div>
                      )}
                  </div>
                  <select
                    className="form-select"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...formEmpleado.getFieldProps('manager.gender.id')}
                  >
                    <option value={0}>Seleccione</option>
                    {genders.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pe-5" style={{ width: '33%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Fecha de nacimiento
                    </label>
                    {formEmpleado.touched.manager?.birthdate &&
                      formEmpleado.errors.manager?.birthdate && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.birthdate}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    value={
                      new Date(formEmpleado.values.manager.birthdate)
                        .toISOString()
                        .split('T')[0]
                    }
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        formEmpleado.setFieldValue('manager.birthdate', fecha);
                      }
                    }}
                  ></input>
                </div>
                {/* <div className="pe-5" style={{ width: '34%' }}>
                  <div className="w-100 d-flex">
                    <label className=" fw-bold fs-6 mb-2 w-40">CURP</label>
                    {formEmpleado.touched.manager?.curp &&
                      formEmpleado.errors.manager?.curp && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.curp}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="CURP"
                    {...formEmpleado.getFieldProps('manager.curp')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="manager.curp"
                    autoComplete="off"
                  />
                </div> */}
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
              <div className="w-100 d-flex">
                {/* <div className="pe-5" style={{ width: '34%' }}>
                  <div className="w-100 d-flex">
                    <label className=" fw-bold fs-6 mb-2 w-40">RFC</label>
                    {formEmpleado.touched.manager?.rfc &&
                      formEmpleado.errors.manager?.rfc && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.rfc}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="RFC"
                    {...formEmpleado.getFieldProps('manager.rfc')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="manager.rfc"
                    autoComplete="off"
                  />
                </div> */}
                <div className="pe-5" style={{ width: '34%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-40">
                      Correo
                    </label>
                    {formEmpleado.touched.manager?.email &&
                      formEmpleado.errors.manager?.email && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.email}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    placeholder="Correo"
                    {...formEmpleado.getFieldProps('manager.email')}
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="manager.email"
                    autoComplete="off"
                  />
                </div>
                <div className="pe-5" style={{ width: '34%' }}>
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-40">
                      Teléfono
                    </label>
                    {formEmpleado.touched.manager?.phoneFormat &&
                      formEmpleado.errors.manager?.phoneFormat && (
                        <div className="fv-plugins-message-container text-danger w-60 ms-5">
                          <span role="alert">
                            {formEmpleado.errors.manager?.phoneFormat}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    value={formEmpleado.values.manager?.phone}
                    placeholder="Teléfono"
                    prefix=""
                    onChange={handlePhoneChange}
                  />
                </div>
              </div>
            </div>

            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formEmpleado.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
