import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function validarIMEI(body: any) {
  const { imei } = body;
  const response = await axios.get(`${API_URL}operation/validation/${imei}`);
  return response;
}
export async function validateICCID(body: any) {
  const { iccid } = body;
  const response = await axios.get(`${API_URL}operation/validation/iccid/${iccid}`);
  return response;
}
export async function validateMSISDN(body: any) {
  const { phoneFormat } = body;
  const response = await axios.post(`${API_URL}users/validateMSISDN/`,{
    phone_number: phoneFormat
  });
  return response;
}
export async function validateEmail(body: any) {
  const { email } = body;
  const response = await axios.get(`${API_URL}users/validation/email/${email}`);
  return response;
}
export async function nuevaPortabilidad(body: any) {
  const { iccid, offer_id, email, first_name, father_last_name, mother_last_name, birthdate, gender_id, imei } = body;
  const response = await axios.post(`${API_URL}operation/portability/`,{
    iccid: iccid,
    offer_id: Number(offer_id),
    schedule_date: null,
    correo: email,
    nombre: first_name,
    apellido_paterno: father_last_name,
    apellido_materno: mother_last_name,
    fecha_nacimiento: birthdate,
    gender_id: Number(gender_id),
    imei: imei,
  });
  return response;
}
export async function nuevaPortabilidadAct(body: any) {
  const { birthdate, email, father_last_name, fecha_portabilidad, first_name, found, gender_id, iccid, iccid_real, mother_last_name, nip, phoneFormat, activation_id, imei } = body;
  const response = await axios.post(`${API_URL}operation/portability/activation`,{
    found: (found === 1 ? true: false),
    nip: nip,
    numero_telefono: phoneFormat,
    fecha_portabilidad: fecha_portabilidad,
    iccid: iccid_real,
    imei: imei,
    user: {
        activation_id: (activation_id ? activation_id : null),
        first_name: first_name,
        father_last_name: father_last_name,
        mother_last_name: mother_last_name,
        email: email,
        birthdate: birthdate,
        gender_id: gender_id
    }
  });
  return response;
}

export async function searchUser(iccid: string) {
  const response = await axios.get(`${API_URL}catalogos/activation/iccid/${iccid}`);
  return response;
}
