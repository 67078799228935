import React, { useState, useEffect } from 'react';

// import { ListGrid } from './ListGrid';
import { useGetAll } from '../hooks/PortabilidadHook';
// import { DownloadExcel } from '../../../../../_mexablock/helpers';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import {
  KTSVG,
  userHasRole,
  SimpleAlert,
  LoadingGrid,
} from '../../../../../_mexablock/helpers';
import { Filter } from '../_modals/Filter';
import { downloadPDF } from '../../../../service/reportes/Portability';
import { TableList } from '../../../../../_mexablock/helpers/components/TableList';

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() - 1,
  1
);
let fecha_fin_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  -1
);

const View: React.FC = () => {
  // OBTIENE ROL USUARIO
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const showFilterCode = userHasRole(
    ['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'],
    userRoles
  );
  const [loadingView, setloadingView] = useState(true);
  const [initFilterValues, setInitFilterValues] = useState({
    type_id: 0,
    init_date: new Date(fecha_inicio_init).toISOString().split('T')[0],
    end_date: new Date(fecha_fin_init).toISOString().split('T')[0],
    filter: 0,
  });
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);
  const [loadingDownload, setloadingDownload] = useState(false);

  useEffect(() => {
    if (data.length) {
      setloadingDownload(false);
    } else {
      setloadingDownload(true);
    }
  }, [data]);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const handleFilter = (values: any) => {
    setInitFilterValues(values);
  };
  const handleFilterClean = () => {
    setInitFilterValues({
      type_id: 0,
      init_date: new Date(fecha_inicio_init).toISOString().split('T')[0],
      end_date: new Date(fecha_fin_init).toISOString().split('T')[0],
      filter: 0,
    });
  };

  const columns: any = [
    {
      id: 'numero_portado',
      name: <div className="fw-bolder text-muted me-5">Numero portado</div>,
      selector: (row: any) => row.numero_portado,
      cell: (row: any) => row.numero_portado,
      sortable: true,
      width: '180px',
    },
    {
        id: 'fecha_portabilidad',
        name: <div className="fw-bolder text-muted me-5">Fecha</div>,
        selector: (row: any) => row.fecha_portabilidad,
        sortable: true,
        width: '220px',
    },
    {
      id: 'oferta',
      name: <div className="fw-bolder text-muted me-5">Oferta</div>,
      selector: (row: any) => row.oferta,
      sortable: true,
      width: '150px',
    },
    {
      id: 'usuario_creo',
      name: <div className="fw-bolder text-muted me-5">Usuario creador</div>,
      selector: (row: any) => row.usuario_creo,
      cell: (row: any) => row.usuario_creo,
      sortable: true,
      width: '150px',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
        selector: (row: any) => row.tipo,
        cell: (row: any) => row.tipo,
        sortable: true,
        width: '150px',
    },
    {
      id: 'iccid',
      name: <div className="fw-bolder text-muted me-5">ICCID</div>,
      selector: (row: any) => row.iccid,
      cell: (row: any) => (
        <span className="text-primary fw-bolder">{row.iccid}</span>
      ),
      sortable: true,
      width: '175px',
    },
  ];

  const handleDownloadPDF = () => {
    setloadingDownload(true);
    setTimeout(() => {
      downloadPDF(initFilterValues)
        .then((response) => {
          setloadingDownload(false);
        })
        .catch((error) => {
          SimpleAlert(error.message, 4000, 'error');
        });
    }, 2000);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={handleFilterClean}
                onSearchFilter={handleFilter}
                showFilterCode={showFilterCode}
              />
              <div className="card-toolbar">
                <button
                  className="btn btn-sm btn-light-danger ms-5"
                  disabled={loadingDownload}
                  onClick={handleDownloadPDF}
                >
                  {loadingDownload && data.length ? (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Descargando...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <>
                      <KTSVG
                        path="/media/icons/duotune/files/fil009.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      Descargar PDF
                    </>
                  )}
                </button>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
