import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getAll(
  filters: any
) {
  const response = await axios.get(
    `${API_URL}employee/all`
  );
  const data = response.data.doc.data;
  return data;
}


export async function addCallCenter( body: any ) {
  const { first_name, father_last_name, mother_last_name, birthdate, email, phoneFormat, gender } = body;
  const data = {
        "first_name": first_name,
        "father_last_name": father_last_name,
        "mother_last_name": mother_last_name,
        "email": email,
        "phone": phoneFormat,
        "birthdate": birthdate,
        "gender_id": (gender.id === 0) ? null : gender.id,
  };
  const response = await axios.post(`${API_URL}employee/add`, data);
  return response;
}
