import React, { useState, useEffect } from 'react';
import { KTSVG, LoadingGrid } from '../../../../../_mexablock/helpers';
import { TableList } from '../../../../../_mexablock/helpers/components/TableList';
import { MenuComponent } from '../../../../../_mexablock/assets/ts/components';

import { CreateModalUsuario } from '../../_modals/CreateModalUsuario';
import { useGetAll } from '../../hooks/EmpresasHook';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../../setup/index';
import { userHasRole, toAbsoluteUrl } from '../../../../../_mexablock/helpers';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API;


const Usuarios: React.FC<any> = ({ datos }) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  const [dataSelected, setDataSelected] = useState({
    id: "",
    first_name: " ",
    father_last_name: " ",
    mother_last_name: " ",
    email: " ",
    birthdate: " ",
    alias: " ",
    phone: " ",
  });

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }

  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'profile_photo',
      className: 'text-center',
      name: '',
      cell: (row: any) => imageRender(row.profile_photo),
      width: '8%',
    },
    {
      id: 'Nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.first_name,
      sortable: true,
      width: '12%',
    },
    {
      id: 'apellidoPaterno',
      name: <div className="fw-bolder text-muted me-5">Apellido Paterno</div>,
      selector: (row: any) => row.father_last_name,
      sortable: true,
      width: '15%',
    },
    {
      id: 'apellidoMaterno',
      name: <div className="fw-bolder text-muted me-5">Apellido Materno</div>,
      selector: (row: any) => row.code,
      cell: (row: any) => (
        <span className="text-primary fw-bolder">{row.mother_last_name}</span>
      ),
      sortable: true,
      width: '15%',
    },
    {
      id: 'email',
      name: <div className="fw-bolder text-muted me-5">Email</div>,
      selector: (row: any) => row.code,
      cell: (row: any) => (
        <span className="text-primary fw-bolder">{row.email}</span>
      ),
      sortable: true,
      width: '18%',
    },
    {
      id: 'birthdate',
      name: <div className="fw-bolder text-muted me-5">Fecha Nacimiento</div>,
      selector: (row: any) => {
        let cadena = (row.birthdate);
        const fecha = cadena.split('T');
        return fecha[0]
      },
      sortable: true,
      width: '15%',
    },
    {
      id: 'phone',
      name: <div className="fw-bolder text-muted me-5">Teléfono</div>,
      selector: (row: any) => row.phone,
      sortable: true,
      width: '10%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '15%',
    },
  ];

  const imageRender = (user: any) => {
    return (
      <>
        <div className="symbol symbol-30px me-5">
          <img
            src={
              user
                ? `${process.env.REACT_APP_PUBLIC}users/${user.profile_photo}`
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            crossOrigin="anonymous"
            className=""
            alt=""
          />
        </div>
      </>
    );
  };

  const onRemoveRegister = (id: string) => {
    axios.delete(`${API_URL}users/userDisable/${id}`)

      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  MenuComponent.reinitialization();


  const dropdownRender = (item: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#"
                onClick={() => {
                  onEdit(item);
                }}
              >
                Editar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  onRemoveRegister(item.id);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const onEdit = (row: any) => {
    setDataSelected({
      id: row.id,
      first_name: row.first_name,
      father_last_name: row.father_last_name,
      mother_last_name: row.mother_last_name,
      email: row.email,
      birthdate: row.birthdate,
      alias: row.alias,
      phone: row.phone,

    });
    setVisivilityModal(!visivilityModal);
  };

  return (
    <>
      <div className="row">
        {loadingView ? (
          <LoadingGrid text="Cargando..."></LoadingGrid>
        ) : (
          <TableList data={datos} columns={columns}></TableList>
        )}
      </div >

      <CreateModalUsuario
        data={dataSelected}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
            window.location.reload();
          }
          setDataSelected({
            id: '',
            first_name: ' ',
            father_last_name: '',
            mother_last_name: '',
            email: '',
            birthdate: '',
            alias: '',
            phone: '',
          });
          setVisivilityModal(!visivilityModal);

        }}
      ></CreateModalUsuario>


    </>
  );
};

export { Usuarios };
