import { useState, useEffect } from 'react'
import { getRecharges } from '../../../../service/reportes/Recargas';
import { getCommissions } from '../../../../service/reportes/Commissions';
import { ReportComisionesModel } from '../models/ReportCommissionsModel';
import { DistribuidorModel } from '../../../distribuidor/models/DistribuidorModel';
import { getAllDistributorsForReport } from '../../../../service/distribuidores/Distribuidores';
import { getAllEmpresas } from '../../../../service/empresas/Empresas';
import { EmpresaModel } from '../../../empresas/models/EmpresaModel';
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, filters: any) => {

    const [data, setData] = useState<ReportComisionesModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getCommissions(filters).then().catch((error) => {
                history.push('/error/500')
            });
            if (res) {
                setData(res.rows);
                setLoadingRequest(1);
            }
        };
        if (realoadGrid) {
            fetchPost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, realoadGrid]);
    return { data, loadingRequest }

}
export const useGetAllDistributor = (execute: boolean) => {

    const [data, setData] = useState<DistribuidorModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getAllDistributorsForReport().then().catch((error) => {
                history.push('/error/500')
            });
            if (res) {
                setData(res);
                setLoadingRequest(1);
            }
        };
        if (execute) {
            fetchPost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [execute]);
    return { data, loadingRequest }

}
export const useGetAllCompany = (execute: boolean) => {

    const [data, setData] = useState<EmpresaModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getAllEmpresas(null).then().catch((error) => {
                history.push('/error/500')
            });
            if (res) {
                setData(res.rows);
                setLoadingRequest(1);
            }
        };
        if (execute) {
            fetchPost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [execute]);
    return { data, loadingRequest }

}

