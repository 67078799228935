import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API;



type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  data: any;
};

const schemaBalance = Yup.object().shape({
  first_name: Yup.string().min(1, ' Requerido.').required('Requerido.'),
  father_last_name: Yup.string().min(1, ' Requerido.').required('Requerido.'),
  mother_last_name: Yup.string().min(1, ' Requerido.').required('Requerido.'),
  email: Yup.string().email('El formato de email es incorrecto').min(1, ' Requerido.').required('Requerido.'),
  birthdate: Yup.string().min(1, ' Requerido.').required('Requerido.'),
  alias: Yup.string().min(1, ' Requerido.').required('Requerido.'),
  phone: Yup.number().min(1, ' Requerido.').required('Requerido.'),

});

const CreateModalUsuario: React.FC<Props> = ({ data, show, handleClose }:any) => {

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [apellidoPaterno, setApellidoPaterno] = useState('');
  const [apellidoMaterno, setApellidoMaterno] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [alias, setAlias] = useState('');
  const [phone, setPhone] = useState('');
  const [muestra, setMuestra] = useState(false);


  const formik = useFormik({
    initialValues: {

      first_name: name, 
      father_last_name: apellidoPaterno , 
      mother_last_name:apellidoMaterno , 
      email:email , 
      birthdate:birthdate , 
      alias:alias, 
      phone:phone },

    validationSchema: schemaBalance,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
         axios.put(`${API_URL}users/ ${id}`, values)

         .then(() => {
           setLoading(false);
           handleClose(true);
           setMuestra(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });

      }, 1000);
      console.log(values);
    },
  });

  const handleCloseModal = () =>{
    setMuestra(false);
  }

  
  useEffect(() => {
    setId(data.id);
    setName(data.first_name);
    setApellidoPaterno(data.father_last_name);
    setApellidoMaterno(data.mother_last_name);
    let cadena = (data.birthdate);
    const fecha = cadena.split('T');
    setBirthdate(fecha[0]);
    setEmail(data.email);
    setAlias(data.alias);
    setPhone(data.phone);
    setMuestra(show);
  }, [data]);



  return (


    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={muestra}
      onHide={() => {
        handleCloseModal();
      }}
    >

        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
              <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
                <div className="card-body py-3 mt-5">
                  <div className="mb-17">
                <div className="d-flex flex-stack mb-5">
                  <h3 className="text-gray-900">Socios</h3>
                </div>

                <div className="modal-body scroll-y mx-5 pt-0">
                <div className="text-center mb-13">
                  <div className="text-muted fw-semibold fs-5">
                    Actualizar información de socio / empresa.
                  </div>
                </div>
            <form onSubmit={formik.handleSubmit} >

            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-auto required">
                    Nombre
                  </label>
                  {formik.touched.first_name && formik.errors.first_name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{formik.errors.first_name}</span>
                      </div>
                  )}
                </div>
                <input
                    placeholder="Nombre"
                    className="form-control"
                    type="text"
                    name="first_name"
                    onChange={(event)=>setName(event.target.value)}
                    value={formik.values.first_name}
                  />
              </div>

                <div className="col-md-4 fv-row fv-plugins-icon-container">
                  <div className="w-100 d-flex">
                    <label className=" fw-bold fs-6 mb-2 w-auto required">
                      Apellido Paterno
                    </label>
                    {formik.touched.father_last_name && formik.errors.father_last_name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{formik.errors.father_last_name}</span>
                      </div>
                    )}
                  </div>
                  <input
                    placeholder="Apellido paterno"
                    className="form-control"
                    type="text"
                    name="father_last_name"
                    autoComplete="off"
                    onChange={(event)=>setApellidoPaterno(event.target.value)}
                    value={formik.values.father_last_name}
                  />
                </div>

                <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Apellido Materno
                  </label>
                  {formik.touched.mother_last_name &&
                    formik.errors.mother_last_name && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formik.errors.mother_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Materno"
                  className="form-control"
                  type="text"
                  name="mother_last_name"
                  onChange={(event)=>setApellidoMaterno(event.target.value)}
                  value={formik.values.mother_last_name}                
                  />
              </div>

              {/* Segundo */}
              <div className="row g-9 mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">

                    
                    Correo
                  </label>
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container text-danger w-60 ms-5">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Correo"
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="email"
                  autoComplete="off"
                  onChange={(event)=>setEmail(event.target.value)}
                  value={formik.values.email}
                />
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Fecha de Nacimiento
                  </label>
                  {formik.touched.birthdate && formik.errors.birthdate && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formik.errors.birthdate}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Fecha de Nacimiento"
                  className="form-control mb-3 mb-lg-0"
                  maxLength={13}
                  type="date"
                  name="birthdate"
                  onChange={(event)=>setBirthdate(event.target.value)}
                  value={formik.values.birthdate}
                />
              </div>
            </div>

            {/* Tercero */}
            <div className="row g-9 mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Alias
                  </label>
                  {formik.touched.alias && formik.errors.alias && (
                    <div className="fv-plugins-message-container text-danger w-60 ms-5">
                      <span role="alert">{formik.errors.alias}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Alias"
                  className="form-control mb-3 mb-lg-0"
                  maxLength={13}
                  type="text"
                  name="alias"
                  autoComplete="off"
                  onChange={(event)=>setAlias(event.target.value)}
                  value={formik.values.alias}
                />
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Teléfono
                  </label>
                  {formik.touched.phone &&
                    formik.errors.phone && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">{formik.errors.phone}</span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Teléfono"
                  className="form-control"
                  type="text"
                  maxLength={21}
                  name="phone"
                  autoComplete="off"
                  onChange={(event)=>setPhone(event.target.value)}
                  value={formik.values.phone}
                />
              </div>
            </div>


            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  // setDocumentName('');
                  // formEmpresa.resetForm();
                handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>

                  </div>
                </div>
                </form>


                </div>
                </div>
                </div>
                </div>
                </div></div>






      
    </Modal>
                
  );
};
export { CreateModalUsuario };
