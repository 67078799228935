import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, toAbsoluteUrl, SimpleAlert } from '../../../helpers';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepperComponent } from '../../../assets/ts/components';
import clsx from 'clsx';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { topUpBalance } from '../../../../app/service/operacion/Cuentas';
import { getUserByToken } from '../../../../app/modules/auth/redux/AuthCRUD';
import * as auth from '../../../../app/modules/auth/redux/AuthRedux';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};
const schemaBalance = [
  Yup.object({
    new_balance: Yup.number()
      .min(1, 'Saldo requerido.')
      .required('Saldo requerido.'),
  }),
  Yup.object({
    payment_id: Yup.number()
      .min(1, 'Método de pago requerido.')
      .required('Método de pago requerido.'),
  }),
  Yup.object({
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
        'Formato de contraseña incorrecta'
      )
      .required('Contraseña es requerida'),
  }),
];
const TopUpBalanceModal: React.FC<Props> = ({ show, handleClose }) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { profile_photo, email, first_name, father_last_name } = user;
  const dispatch = useDispatch();
  const [visivilityConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [textStepper, setTextStepper] = useState('Continuar');
  const [currentSchema, setCurrentSchema] = useState(schemaBalance[0]);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (show) {
      loadStepper();
      setReadOnly(true);
    }
  }, [stepperRef, show]);

  const formBalance = useFormik({
    initialValues: {
      new_balance: 0,
      payment_id: 1,
      password: '',
    },
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            if (!stepper.current) {
              return;
            }
            stepper.current.goNext();
            setCurrentSchema(schemaBalance[1]);
            setLoading(false);
          }, 1000);

          break;
        case 2:
          setLoading(true);
          setTimeout(() => {
            if (!stepper.current) {
              return;
            }
            stepper.current.goNext();
            setCurrentSchema(schemaBalance[2]);
            setLoading(false);
            setTextStepper('Recargar');
          }, 1000);
          break;
        case 3:
          setLoading(true);
          setTimeout(() => {
            topUpBalance(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                const {
                  data: { message },
                } = res;
                SimpleAlert(message, 3000, 'success');
                const user = await getUserByToken();
                dispatch(auth.actions.fulfillUser(user));
                stepper.current.goto(1);
                formBalance.resetForm();
                setTextStepper('Continuar');
                setCurrentSchema(schemaBalance[0]);
                handleClose(true);
                setLoading(false);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 2000);
          break;
      }
    },
  });
  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(schemaBalance[stepper.current.currentStepIndex - 1]);
    switch (stepper.current.currentStepIndex - 1) {
      case 1:
        setTextStepper('Continuar');
        break;
      case 2:
        setTextStepper('Continuar');
        break;
    }
  };
  return (
    <Modal
      id="kt_modal_top_up_balance"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        stepper?.current?.goto(1);
        formBalance.resetForm();
        setCurrentSchema(schemaBalance[0]);
        setTextStepper('Continuar');
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              stepper?.current?.goto(1);
              formBalance.resetForm();
              setCurrentSchema(schemaBalance[0]);
              setTextStepper('Continuar');
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body  pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Recargar de saldo</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Saldo a recargar</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Método de pago</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Autenticación</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-600px pt-10 pb-10 fv-plugins-bootstrap5 fv-plugins-framework"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formBalance.handleSubmit}
            >
              <div className="current" data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="pb-5 pb-lg-10">
                    <h2 className="fw-bold d-flex align-items-center text-dark">
                      Establece la cantidad a recargar
                    </h2>

                    <div className="text-muted fw-semibold fs-6">
                      Si necesita más información, consulte
                      <a href="#/" className="link-primary fw-bold ms-1">
                        la sección de ayuda
                      </a>
                      .
                    </div>
                  </div>
                  <div className="mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Saldo a recargar
                      </label>
                      {formBalance.touched.new_balance &&
                        formBalance.errors.new_balance && (
                          <div className="fv-plugins-message-container text-danger  ms-5">
                            <span role="alert">
                              {formBalance.errors.new_balance}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="new_balance"
                      type="text"
                      autoComplete="off"
                      className="form-control mb-3"
                      value={formBalance.values.new_balance}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      allowNegative={false}
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formBalance.setFieldValue(
                          'new_balance',
                          floatValue ? floatValue : 0
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div data-kt-stepper-element="content" className="pending">
                <div className="w-100">
                  <div className="pb-5 pb-lg-10">
                    <h2 className="fw-bold d-flex align-items-center text-dark">
                      Mis métodos de pago
                    </h2>
                    <div className="text-muted fw-semibold fs-6">
                      Para agregar una nueva tarjeta
                      <a href="#/" className="link-primary fw-bold ms-1">
                        click aquí
                      </a>
                      .
                    </div>
                  </div>
                  <div className="" data-kt-modal-top-up-wallet-option="dollar">
                    <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <div className="w-100 d-flex mb-2">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Seleccione un método de pago
                        </label>
                        {formBalance.touched.payment_id &&
                          formBalance.errors.payment_id && (
                            <div className="fv-plugins-message-container text-danger w-50  ms-5">
                              <span role="alert">
                                {formBalance.errors.payment_id}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="row row-cols-1 row-cols-md-2 g-5">
                        <div
                          className="col"
                          onClick={() => {
                            formBalance.setFieldValue(`payment_id`, 1);
                          }}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed p-7 d-flex align-items-start gap-5 h-100',
                              {
                                'btn-active-light-primary active':
                                  formBalance.values.payment_id === 1,
                              }
                            )}
                            data-kt-button="true"
                          >
                            <img
                              className="w-50px"
                              src={toAbsoluteUrl(
                                '/media/svg/payments/visa.svg'
                              )}
                              alt=""
                            />

                            <div className="d-flex align-items-start flex-column gap-3">
                              <div className="d-flex align-items-center">
                                <span>Personal</span>

                                <div className="badge badge-primary ms-5">
                                  Predeterminado
                                </div>
                              </div>

                              <div className="text-dark fw-bold">**** 8742</div>

                              <div className="text-muted">Exp 01/23 </div>
                            </div>
                          </label>
                        </div>

                        <div
                          className="col"
                          onClick={() => {
                            formBalance.setFieldValue(`payment_id`, 2);
                          }}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed p-7 d-flex align-items-start gap-5 h-100',
                              {
                                'btn-active-light-primary active':
                                  formBalance.values.payment_id === 2,
                              }
                            )}
                            data-kt-button="true"
                          >
                            <img
                              className="w-50px"
                              src={toAbsoluteUrl(
                                '/media/svg/payments/mastercard.svg'
                              )}
                              alt=""
                            />

                            <div className="d-flex align-items-start flex-column gap-3">
                              <div className="d-flex align-items-center">
                                <span>Empresa</span>
                              </div>

                              <div className="text-dark fw-bold">**** 1141</div>

                              <div className="text-muted">Exp 24/05 </div>
                            </div>
                          </label>
                        </div>
                        {/* <div
                          className="col"
                          onClick={() => {
                            formBalance.setFieldValue(`payment_id`, 3);
                          }}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed p-7 d-flex align-items-start gap-5 h-100',
                              {
                                'btn-active-light-primary active':
                                  formBalance.values.payment_id === 3,
                              }
                            )}
                            data-kt-button="true"
                          >
                            <img
                              className="w-50px"
                              src={toAbsoluteUrl(
                                '/media/svg/payments/mastercard.svg'
                              )}
                              alt=""
                            />

                            <div className="d-flex align-items-start flex-column gap-3">
                              <div className="d-flex align-items-center">
                                <span>Empresa</span>
                              </div>
                              <div className="text-dark fw-bold">**** 8839</div>
                              <div className="text-muted">Exp 07/24 </div>
                            </div>
                          </label>
                        </div> */}

                        {/* <div
                          className="col"
                          onClick={() => {
                            formBalance.setFieldValue(`payment_id`, 0);
                          }}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed p-7 d-flex flex-column flex-center gap-5 h-100',
                              {
                                'btn-active-light-primary active':
                                  formBalance.values.payment_id === 0,
                              }
                            )}
                            data-kt-button="true"
                          >
                            <KTSVG
                              path="/media/icons/duotune/general/gen041.svg"
                              svgClassName="h-50px w-50px"
                            />

                            <div className="fs-5 fw-bold">
                              Agregar nueva tarjeta
                            </div>
                          </label>
                        </div> */}
                      </div>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-kt-stepper-element="content" className="pending">
                <div className="w-100">
                  <div className="pb-5 pb-lg-10">
                    <h2 className="fw-bold d-flex align-items-center text-dark">
                      Autenticación
                    </h2>
                    <div className="text-muted fw-semibold fs-6">
                      Si olvidaste tu contraseña puedes recuperarla dando
                      <a href="#/" className="link-primary fw-bold ms-1">
                        click aquí
                      </a>
                      .
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-10">
                    <div className="d-flex align-items-center gap-5">
                      <div className="symbol symbol-50px symbol-lg-50px symbol-fixed position-relative">
                        <img
                          src={profile_photo}
                          crossOrigin="anonymous"
                          alt="Metornic"
                        />
                      </div>

                      <div className="fw-bold">
                        <span className="fw-bold d-flex align-items-center text-gray-800 fs-5">
                          {first_name} {father_last_name}
                        </span>
                        <div className="text-muted fw-semibold fs-5">
                          {email}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Introduce tú contraseña
                      </label>
                      {formBalance.touched.password &&
                        formBalance.errors.password && (
                          <div className="fv-plugins-message-container text-danger  ms-5">
                            <span role="alert">
                              {formBalance.errors.password}
                            </span>
                          </div>
                        )}
                    </div>
                    <div className={clsx('position-relative')}>
                      <input
                        type={visivilityConfirm ? 'text' : 'password'}
                        className="form-control mb-3"
                        autoComplete="off"
                        onFocus={(e: any) => {
                          setReadOnly(false);
                        }}
                        readOnly={readOnly}
                        {...formBalance.getFieldProps('password')}
                      />
                    </div>
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                      <div data-field="top_up_amount" data-validator="notEmpty">
                        Se requiere la cantidad de recarga
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr063.svg"
                      className="svg-icon-4 me-1"
                    />
                    Regresar
                  </button>
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { TopUpBalanceModal };
