import axios from 'axios'
const API_URL = process.env.REACT_APP_API

const sumDayDate = (date: string)  => {
    let fechaInicial = new Date(date);
    fechaInicial.setDate(fechaInicial.getDate() + 1);
    let fechaFormateada = fechaInicial.toISOString().slice(0, 10);
    return fechaFormateada;
}

export async function getPartners(  filters: any ){

  let search: string = '';
  if (filters.type_id !== 0) {
    search += `&type_id=${filters.type_id}`;
  }
  if (filters.init_date) {
    search += `&init_date=${filters.init_date}`;
  }
  if (filters.end_date) {
    search += `&end_date=${(filters.end_date)}`;
  }
  const response = await axios.get(
    `${API_URL}reports/partners?sort=id${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function downloadPDF( filters: any) {

    let search: string = '';
    let date = new Date().toISOString().split('T')[0];
    if (filters.type_id !== 0) {
      search += `&type_id=${filters.type_id}`;
    }
    if (filters.init_date) {
      search += `&init_date=${filters.init_date}`;
    }
    if (filters.end_date) {
      search += `&end_date=${(filters.end_date)}`;
    }
    const response = await axios({
        url: `${ API_URL }reports/partners/document/pdf?sort=id${search}`,
        method: 'GET',
        responseType: 'blob',
    })
    .then(response => {

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `REPORTE-SOCIOS-${ date }.pdf`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
        return false;

    })
    .catch(async error => {
        return JSON.parse(await error.response.data.text());
    });
    return response;

}

export async function getSelectTypes() {
  const response = await axios.get(`${API_URL}catalogs/TCTA`);
  const data = response.data.doc.data.rows;
  let dataSelect: any = data;
  dataSelect.forEach(( row:any ) => {
      row.label = row.name;
      row.value = row.id;
  });
  return dataSelect;
}