import axios from 'axios';
const API_URL = process.env.REACT_APP_API;


export const getModeApp = async () => {
    const response = await axios.get(
        `${API_URL}catalogos/generalParameters/TMOD`
    );
    return response.data.doc.data;
};

export const updateParamTestMode = async (code: string, value: any) => {
    const response = await axios.put(`${API_URL}catalogos/generalParameters/`, {
        code: code,
        value: value,
    });
    return response;
}