import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import {
  KTSVG,
  LoadingGrid,
  maskPhoneNumber,
  SimpleAlert,
} from '../../../../../_mexablock/helpers';
import { TableList } from '../../../../../_mexablock/helpers/components/TableList';
import { PortabilityModal } from '../_modals/PortabilityModal';
import { PortabilityActivationModal } from '../_modals/PortabilityActivationModal';
import { PortabilitySendModal } from '../_modals/PortabilitySendModal';
import { NoBalanceModal } from '../../../../../_mexablock/partials/modals/balance/NoBalanceModal';
import { TopUpBalanceModal } from '../../../../../_mexablock/partials/modals/balance/TopUpBalanceModal';
import { useGetAll } from '../hooks/PortabilidadHook';
import { ConfirmModal } from '../_modals/ConfirmModal';
import { MenuComponent } from '../../../../../_mexablock/assets/ts/components';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { saldo } = user;
  const [loadingView, setloadingView] = useState(true);
  const [id, setId] = useState(0);
  const [statusId, setStatusId] = useState(0);

  const [dataRow, setDataRow] = useState(0);
  const [visivilitySendModal, setVisivilitySendModal] = useState(false);

  const [visivilityConfirmModal, setVisivilityConfirmModal] = useState(false);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityTopUpModal, setVisivilityTopUpModal] = useState(false);
  const [visivilityNoBalanceModal, setVisivilityNoBalanceModal] =
    useState(false);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const [visivilityRechargeModal, setVisivilityRechargeModal] = useState(false);
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    if (Number(saldo) > 0) {
      setVisivilityModal(!visivilityModal);
    } else {
      setVisivilityNoBalanceModal(!visivilityNoBalanceModal);
    }
  };
  const topUpMultipleBalance = () => {
    setVisivilityTopUpModal(!visivilityTopUpModal);
  };
  const handlerSendPortability = (id: number) => {
    setDataRow(id);
    setVisivilitySendModal(!visivilitySendModal);
  };

  const changeStatus = (id: number, statusId: number) => {
    setId(id);
    setStatusId(statusId);
    setVisivilityConfirmModal(!visivilityConfirmModal);
  };

  const columns: any = [
    // {
    //   id: 'fecha',
    //   name: <div className="fw-bolder text-muted me-5">Fecha</div>,
    //   selector: (row: any) => row.date_portability,
    //   cell: (row: any) => row.date_portability,
    //   sortable: true,
    //   width: '150px',
    // },
    {
      id: 'folio',
      name: <div className="fw-bolder text-muted me-5">Folio</div>,
      selector: (row: any) => row.folio,
      sortable: true,
      width: '150px',
    },
    {
      id: 'msisdn',
      name: <div className="fw-bolder text-muted me-5">MSISDN</div>,
      selector: (row: any) =>
        row.phone_number && maskPhoneNumber(row.phone_number),
      sortable: true,
      width: '150px',
    },
    {
      id: 'iccid',
      name: <div className="fw-bolder text-muted me-5">ICCID</div>,
      selector: (row: any) => row.iccid,
      sortable: true,
      width: '250px',
    },

    {
      id: 'codigo',
      name: <div className="fw-bolder text-muted me-5">Código</div>,
      selector: (row: any) => row.code_created_by,
      cell: (row: any) => (
        <span className="text-primary fw-bolder">{row.code_created_by}</span>
      ),
      sortable: true,
      width: '150px',
    },
    {
      id: 'usuario',
      name: <div className="fw-bolder text-muted me-5">Usuario</div>,
      selector: (row: any) =>
        `${row.customer.first_name} ${row.customer.father_last_name} ${row.customer.mother_last_name}`,
      sortable: true,
      width: '300px',
    },
    {
      id: 'estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.status.name,
      cell: (row: any) => (
        <>
          {row.status.code === 'PEN' && (
            <span className="badge py-3 px-4 fs-7 badge-light-warning">
              {row.status.name}
            </span>
          )}
          {row.status.code === 'PENV' && (
            <span className="badge py-3 px-4 fs-7 badge-light-primary">
              {row.status.name}
            </span>
          )}
          {row.status.code === 'ERR' && (
            <span className="badge py-3 px-4 fs-7 badge-light-danger">
              {row.status.name}
            </span>
          )}
          {row.status.code === 'EXT' && (
            <span className="badge py-3 px-4 fs-7 badge-light-success">
              {row.status.name}
            </span>
          )}
        </>
      ),
      sortable: true,
      width: '220px',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '180px',
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        {row.status.code !== 'EXT' && (
          <div className="d-flex justify-content-end flex-shrink-0">
            <a
              href="#/"
              data-kt-menu="true"
              className="btn btn-light btn-active-light-primary btn-sm"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              Acciones
              <span className="svg-icon svg-icon-5 m-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <path
                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </a>

            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-auto py-4"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                {row.status.code === 'PEN' && (
                  <>
                    <a
                      className="menu-link px-3"
                      href="#/"
                      onClick={() => {
                        changeStatus(Number(row.id), 40);
                      }}
                    >
                      <div className="svg-icon-1 svg-icon">Error</div>
                    </a>
                    <a
                      className="menu-link px-3"
                      href="#/"
                      onClick={() => {
                        changeStatus(Number(row.id), 41);
                      }}
                    >
                      <div className="svg-icon-1 svg-icon ">Exitosa</div>
                    </a>
                  </>
                )}
                {row.status.code === 'PENV' && (
                  <a
                    className="menu-link px-3"
                    href="#/"
                    onClick={() => {
                      handlerSendPortability(row.id);
                    }}
                  >
                    <div className="svg-icon-1 svg-icon">Enviar a portar</div>
                  </a>
                )}

                {row.status.code === 'ERR' && (
                  <a
                    className="menu-link px-3"
                    href="#/"
                    onClick={() => {
                      handlerSendPortability(row.id);
                    }}
                  >
                    <div className="svg-icon-1 svg-icon">Reenviar a portar</div>
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  data-kt-menu="true"
                  className="btn btn-sm btn-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nueva portabilidad
                </a>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <a
                      className="menu-link px-3"
                      data-kt-users-table-filter="delete_row"
                      href="#/"
                      onClick={addRegister}
                    >
                      Iniciar
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      className="menu-link px-3"
                      data-kt-users-table-filter="delete_row"
                      href="#/"
                      onClick={topUpMultipleBalance}
                    >
                      Desde una activación
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
        <PortabilityModal
          show={visivilityModal}
          handleClose={(updateGrid: boolean) => {
            if (updateGrid) {
              // setLoadingGrid(true);
              setReloadGrid(Math.random() * 40);
            }
            // setIdRow(0);
            setVisivilityModal(!visivilityModal);
          }}
        ></PortabilityModal>
      </div>
      <PortabilitySendModal
        id={dataRow}
        show={visivilitySendModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setDataRow(0);
          setVisivilitySendModal(!visivilitySendModal);
        }}
      ></PortabilitySendModal>
      <PortabilityActivationModal
        show={visivilityTopUpModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setDataRow(0);
          setVisivilityTopUpModal(!visivilityTopUpModal);
        }}
      ></PortabilityActivationModal>
      {/* <PortabilityMultipleModal
        id_row={idRow}
        show={visivilityTopUpModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
          }
          setIdRow(0);
          setVisivilityTopUpModal(!visivilityTopUpModal);
        }}
      ></PortabilityMultipleModal> */}
      <NoBalanceModal
        show={visivilityNoBalanceModal}
        balance={Number(saldo)}
        handleClose={(updateGrid: boolean) => {
          setVisivilityNoBalanceModal(!visivilityNoBalanceModal);
        }}
        rechargeBalance={() => {
          setVisivilityNoBalanceModal(!visivilityNoBalanceModal);
          setVisivilityRechargeModal(!visivilityRechargeModal);
        }}
      ></NoBalanceModal>
      <TopUpBalanceModal
        show={visivilityRechargeModal}
        handleClose={(updateData: boolean) => {
          setVisivilityRechargeModal(!visivilityRechargeModal);
        }}
      />
      <ConfirmModal
        show={visivilityConfirmModal}
        id={id}
        statusId={statusId}
        handleClose={(updateData: boolean) => {
          if (updateData) {
            setReloadGrid(Math.random() * 40);
          }
          setId(0);
          setStatusId(0);
          setVisivilityConfirmModal(!visivilityConfirmModal);
        }}
      ></ConfirmModal>
    </>
  );
};

export { View };
