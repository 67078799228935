import React, { useState, useEffect, useRef } from 'react';
import { KTSVG } from '../../../../../_mexablock/helpers';
import { useFormik } from 'formik';
import {
  useGetAllDistributor,
  useGetAllCompany,
} from '../hooks/CommissionsHook';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  showFilterCode: boolean;
  userRoles: any;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  showFilterCode,
  userRoles,
}) => {
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [cuentaSelected, setCuentaSelected] = useState(0);
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisibleDisitributor, setIsVisibleDisitributor] = useState(false);
  const [isVisibleCompany, setIsVisibleCompany] = useState(false);
  const { data: distributors } = useGetAllDistributor(isVisibleDisitributor);
  const { data: company } = useGetAllCompany(isVisibleCompany);
  const [optionsCuenta, setOptionsCuenta] = useState<any>([
    {
      value: 2,
      name: ' Empresa/Socio',
    },
    {
      value: 3,
      name: 'Distribuidor',
    },
  ]);

  const roles = {
    ADMIN_MEXABLOCK: 'ADMIN_MEXABLOCK',
    ADMIN_PARTNER: 'ADMIN_PARTNER',
    ADMIN_COMPANY: 'ADMIN_COMPANY',
    DISTRIBUTOR: 'DISTRIBUTOR',
  };
  const cuentas = {
    COMPANY: 2,
    DISTRIBUTOR: 3,
  };

  useEffect(() => {
    if (showFilterCode && userRoles.length) {
      userRoles.forEach((element: any) => {
        switch (element.code) {
          case roles.ADMIN_MEXABLOCK:
            setOptionsCuenta([
              {
                value: 2,
                name: ' Empresa/Socio',
              },
              {
                value: 3,
                name: 'Distribuidor',
              },
            ]);
            break;
          case roles.ADMIN_PARTNER:
            setOptionsCuenta([
              {
                value: 3,
                name: 'Distribuidor',
              },
            ]);
            break;

          default:
            break;
        }
      });
    } else {
    }
  }, [showFilterCode, userRoles]);

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  useEffect(() => {
    switch (cuentaSelected) {
      case cuentas.DISTRIBUTOR:
        setIsVisibleDisitributor(!isVisibleDisitributor);
        break;
      case cuentas.COMPANY:
        setIsVisibleCompany(!isVisibleCompany);
        break;
      default:
        break;
    }
  }, [cuentaSelected]);

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter({ ...values, filter: 1 });
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column">
        <a
          href="#/"
          className={
            formFilter.values.filter !== 0
              ? ' btn btn-sm btn-primary'
              : 'btn btn-sm btn-light-primary'
          }
          data-kt-menu-trigger="click"
          data-kt-menu-placement="right-start"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-300px w-md-300px ms-2"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <div className="separator border-gray-200"></div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">Fecha inicial:</label>
                <input
                  type="date"
                  className="form-control"
                  ref={dateInputRef}
                  onKeyDown={(e) => e.preventDefault()}
                  value={formFilter.values.init_date}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formFilter.setFieldValue('init_date', fecha);
                    }
                  }}
                ></input>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Fecha fin:</label>
                <input
                  type="date"
                  className="form-control"
                  onKeyDown={(e) => e.preventDefault()}
                  value={formFilter.values.end_date}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formFilter.setFieldValue('end_date', fecha);
                    }
                  }}
                ></input>
              </div>
              {showFilterCode ? (
                <div className="mb-5">
                  <label className="form-label fw-bold">Tipo de cuenta:</label>
                  <select
                    className="form-select"
                    onChange={(event) => {
                      setIsVisibleDisitributor(false);
                      setIsVisibleCompany(false);
                      setCuentaSelected(parseInt(event.target.value));
                    }}
                  >
                    <option value={0}>Seleccione</option>
                    {optionsCuenta.map((item: any, index: number) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {isVisibleDisitributor ? (
                <div className="mb-5">
                  <label className="form-label fw-bold">Distribuidor:</label>
                  <select
                    className="form-select"
                    {...formFilter.getFieldProps('codeAccount')}
                  >
                    <option value={0}>Seleccione</option>
                    {distributors.map((item: any, index: number) => (
                      <option key={index} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {isVisibleCompany ? (
                <div className="mb-5">
                  <label className="form-label fw-bold">Empresa/Socio:</label>
                  <select
                    className="form-select"
                    {...formFilter.getFieldProps('codeAccount')}
                  >
                    <option value={0}>Seleccione</option>
                    {company.map((item: any, index: number) => (
                      <option key={index} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    setSelectedOption(null);
                    setIsVisibleDisitributor(false);
                    setIsVisibleCompany(false);
                    setCuentaSelected(0);
                    onCleanFilter();
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
