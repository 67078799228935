import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { Modal } from 'react-bootstrap-v5';

type Props = {
  show: boolean;
  balance: number;
  handleClose: (updateData: boolean) => void;
  rechargeBalance: () => void;
};

const NoBalanceModal: React.FC<Props> = ({
  show,
  balance,
  handleClose,
  rechargeBalance,
}) => {
  return (
    <Modal
      id="kt_modal_no_balance"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body pt-0 d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center">
          <div className="mb-10">
            <div className="fs-2hx fw-bold text-gray-800 text-center mb-13">
              <span className="me-2">
                Ups! al parecer te has quedado sin{' '}
                <span className="position-relative d-inline-block text-danger">
                  <span className="text-danger opacity-75-hover">saldo.</span>

                  <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
                </span>
              </span>
            </div>

            <div className="text-center">
              <a
                href="#/"
                className="btn btn-sm btn-success fw-bold"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_upgrade_plan"
                onClick={rechargeBalance}
              >
                Recargar saldo
              </a>
            </div>
          </div>

          <img
            className="mx-auto h-150px h-lg-200px"
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/4.png')}
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
};

export { NoBalanceModal };
