import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as _redux from './setup';
import store, { persistor } from './setup/redux/Store';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { App } from './app/App';
import { MetronicI18nProvider } from './_mexablock/i18n/Metronici18n';
import './_mexablock/assets/sass/style.scss';
import './_mexablock/assets/sass/style.react.scss';

const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);

Chart.register(...registerables);

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Cargando...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
);
