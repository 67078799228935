import React, { useState } from 'react';

type Props = {
  currentPage?: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
};

const Pagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const [pagesToShow, setPagesToShow] = useState(5);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleClick = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const handleIncrement = () => {
    setPagesToShow(pagesToShow + 5);
  };

  const handleDecrement = () => {
    setPagesToShow(pagesToShow - 5);
  };

  const getButtonClasses = (pageNumber: number) => {
    let classes = 'page-item';

    if (pageNumber === currentPage) {
      classes += ' active';
    }

    return classes;
  };

  return (
    <ul className="pagination">
      <li className="page-item previous">
        <button
          className="page-link"
          onClick={handleDecrement}
          disabled={pagesToShow <= 5}
        >
          <i className="previous"></i>
        </button>
      </li>
      {pageNumbers.slice(pagesToShow - 5, pagesToShow).map((pageNumber) => (
        <li key={pageNumber} className={getButtonClasses(pageNumber)}>
          <button className="page-link" onClick={() => handleClick(pageNumber)}>
            {pageNumber}
          </button>
        </li>
      ))}
      {totalPages > pagesToShow && (
        <li className="page-item">
          <button className="page-link">...</button>
        </li>
      )}
      <li className="page-item next">
        <button
          className="page-link"
          onClick={handleIncrement}
          disabled={pagesToShow >= totalPages}
        >
          <i className="next"></i>
        </button>
      </li>
    </ul>
  );
};

export { Pagination };
