import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getAllCuentas } from '../../../service/dashboard/Dashboard'
import { CuentaModel
 } from '../../operacion/cuentas/models/CuentaModel'

export const useSelectCuentas = (realoadGrid: number) => {

    const [data, setData] = useState<CuentaModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
        setLoadingRequest(0);
        const res = await getAllCuentas()
            .then()
            .catch((error) => {
            history.push('/error/500');
            });
        if (res) {
            setData(res.rows);
            setCount(res.count);
            setLoadingRequest(1);
        }
        };
        if (realoadGrid) {
        fetchPost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realoadGrid]);
  return { data, count, loadingRequest };
  };
