import { useState, useEffect } from 'react';
import {
  getAllEmpleados,
  getOneEmpleados
} from '../../../service/empleados/Empleados';
import { EmpleadoModel } from '../models/EmpleadoModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<EmpleadoModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllEmpleados(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    employee_number: '',
    rfc: "",
    manager: {
      id: 0,
      alias: "",
      first_name: "",
      father_last_name: "",
      mother_last_name: "",
      email: "",
      phone: "",
      phoneFormat: "",
      birthdate: new Date(),
      active: false,
      gender: {
        id: 0,
        code: 'SEL',
        name: 'Seleccione'
      },
    }
  };
  const [initValues, setInitValues] = useState<EmpleadoModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOneEmpleados(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};