import { FC } from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../../_mexablock/assets/json/animation_llpha9ks.json';

const Error500: FC = () => {
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Error del sistema</h1>
      <div className="fw-bold fs-3 text-gray-400">
        ¡Algo salió mal! <br /> Por favor, inténtelo de nuevo más tarde.
      </div>
      {/* <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Error del sistema</h1> */}
      <div className="d-flex align-items-center flex-column flex-column-fluid text-center">
        <div className="w-400px">
          <Lottie animationData={animationData} />
        </div>
      </div>
    </>
  );
};

export { Error500 };
