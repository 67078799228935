/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as auth from '../redux/AuthRedux';
import { login } from '../redux/AuthCRUD';
import { toast } from 'react-toastify';
import { SimpleAlert } from '../../../../_mexablock/helpers';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Correo es requerido.'),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
      'Formato de contraseña incorrecta'
    )
    .required('Contraseña es requerido.'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [visivility, setVisivility] = useState(false);
  const validateVisibility = () => {
    setVisivility(!visivility);
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        login(values.email, values.password)
          .then((res) => {
            const { accessToken } = res.data;
            setLoading(false);
            dispatch(auth.actions.login(accessToken));
            toast(`Bienvenido!`, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          })
          .catch((err) => {
            let resMessageToast: string = '';
            if (err.response && err.response.data) {
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            } else {
              SimpleAlert('Error al iniciar sesión', 3000, 'error');
              setLoading(false);
              setSubmitting(false);
            }
          });
      }, 1000);
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Iniciar Sesión</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Correo</label>
        <input
          placeholder="Correo"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg'
            // {'is-invalid': formik.touched.email && formik.errors.email},
            // {
            //   'is-valid': formik.touched.email && !formik.errors.email,
            // }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Contraseña
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: '5px' }}
            >
              Olvidó su contraseña ?
            </Link>
            {/* end::Link */}
          </div>
        </div>

        <div className={clsx('position-relative')}>
          <input
            type={visivility ? 'text' : 'password'}
            placeholder="Contraseña"
            autoComplete="off"
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg'
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
          />
          <span
            className={clsx(
              'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
            )}
            data-kt-password-meter-control={'visibility'}
            onClick={validateVisibility}
          >
            {visivility ? (
              <i className={clsx('bi bi-eye fs-2')}></i>
            ) : (
              <i className={clsx('bi bi-eye-slash fs-2')}></i>
            )}
          </span>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continuar</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Espere por favor...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
